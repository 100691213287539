.preLaunch {
  .toast {
    top: 0;
  }
  .logo {
    width: 150px;
    margin: 1rem auto;
  }
  .preSection {
    text-align: center;
    margin: 0rem 0;
    padding: 0 1rem;
  }
  footer {
    min-height: 8rem !important;
  }
  .fullWidthImage {
    background-image: url("/images/Malen.jpg");
    background-position: 50%;
    height: 460px;
    figure {
      display: none;
    }
  }
  ol {
    max-width: 900px;
    margin: 1rem auto;
    li {
      text-align: left;
      margin-left: 1.5rem;
      margin-bottom: 0.5rem;
      list-style-position: outside;
      font-size: 1.2rem;
      line-height: 1.8rem;
      @include sm {
        font-size: 1.1rem;
        line-height: 1.6rem;
      }
    }
  }
  .when {
    color: $primary;
    font-size: 3.6rem;
    margin-top: 0.6rem;
    margin-bottom: 1.8rem;
    @include sm {
      font-size: 2.6rem;
    }
  }
  .newsletter {
    input {
      padding: 0.5rem 1rem !important;
      width: 280px;
    }
    button {
      padding: 0.8rem 2rem !important;
    }
  }
  .primary {
    color: $primary;
  }
  h1 {
    font-family: "Lubalin Graph Std Book", Arial, sans-serif;
    color: $primary;
    font-size: 3em;
  }
  h2 {
    font-family: "Lubalin Graph Std Book", Arial, sans-serif;
    font-size: 2rem;
    max-width: 1100px;
    &.was {
      line-height: 2.6rem;
    }
    &.h3 {
      max-width: 1100px;
      font-size: 1.2rem;
      line-height: 1.8rem;
      font-weight: normal;
    }
  }
  p {
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.1rem;
    line-height: 1.6rem;
  }

  .newsletter {
    @include displayFlex($alignItems: center, $justifyContent: center);
    gap: 0 0.75rem;
    margin-bottom: 0;
    margin-bottom: 0;

    @include lg {
      //margin-top: 1.5rem;
      display: grid;
      justify-content: center;
      //gap: 1.5rem 2rem;
      //grid-template-columns: repeat(2, 1fr);
      //width: 100%;
    }

    @include sm {
      @include displayFlex($flexDirection: column, $alignItems: center, $justifyContent: flex-end);
      gap: $gap-size-normal;
    }

    p {
      display: none;
      //color: $white;
      //font-family: $font-family-heading;
      letter-spacing: -0.38;
      margin-bottom: 0;
      @include lg {
        text-align: center;
        grid-column-start: 1;
        grid-column-end: 4;
        grid-row-start: 1;
        grid-row-end: 3;
      }
    }

    input {
      background-color: $white !important;
      color: $black;
      &::placeholder {
        color: $black;
      }

      @include lg {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 3;
        grid-row-end: 3;
      }

      @include sm {
        width: $max-percentage;
      }
    }

    button {
      padding: 0.25rem 1.2rem;
      @include lg {
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row-start: 3;
        grid-row-end: 4;
      }
    }
  }

  .nlr {
    padding: $gap-half-size;

    &.nlErr {
      color: $primary;
    }

    &.nlOK {
      color: $white;
    }

    + .nlr {
      padding-top: 0;
    }
  }
  .socials {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    justify-content: center;
    margin: 2rem auto;

    a {
      width: 36px;
      height: 36px;
      position: relative;
      &:hover svg {
        animation: scaleIcon 0.4s ease-in-out forwards;
      }
      + a {
        margin-left: 2rem;
      }
    }

    .facebook svg,
    .instagram svg {
      fill: $black;
      transition: 0.2s fill ease-out;
      animation: scaleIconOut 0.4s ease-in-out forwards;

      &:hover {
        transition: 0.2s fill ease-in;
        fill: $primary;
      }
    }
  }
}
