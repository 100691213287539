.content {
  .contentPanelWithFilters {
    @include lg {
      padding-top: 1rem;
    }
  }
}

.contentPanelWithFilters {
  width: 20%;
  padding-right: 1.25rem;
  padding-left: 2.5rem;
  position: sticky;
  left: 0;
  height: calc(100vh - $header-height-shrink - 50px - 2.5rem);
  overflow-y: scroll;
  overflow-x: visible;
  background-color: $white;
  z-index: 10;

  @include lg {
    width: calc(100% + 5rem);
    margin-left: -2.5rem;
    padding: 0.25rem 2.5rem 0;
    height: 100%;
    // box-shadow: 0 4px 2px -2px rgba(95, 31, 31, 0.22);
    box-shadow: none;

    &.visible {
      position: relative;
    }
  }

  @include sm {
    width: calc(100% + 2 * $gap-size-normal);
    margin-left: -$gap-size-normal;
    padding: 0.25rem $gap-size-normal 0;
    overflow: hidden;
  }

  @include sm {
    &.visible {
      .contentPanelWithFiltersInner {
        overflow: visible;
        padding-bottom: $gap-size-normal;
        > h2 {
          @include lg {
            opacity: 1;
            display: block;
            @include transitionIn(opacity);
          }
        }

        .categoriesBtns {
          opacity: 1;
          height: 100%;
          width: 100%;
          @include transitionIn(opacity);

          + .filters {
            margin-top: $gap-size-normal;
          }
        }
      }
    }
  }

  .contentPanelWithFiltersInner {
    width: $max-percentage;
    height: $max-percentage;
    padding-bottom: $gap-size-normal;
    @include sm {
      padding-bottom: $gap-half-size;
      margin-top: 0;
      overflow: hidden;
    }

    > h2 {
      @include lg {
        margin-bottom: 0;
        font-size: fontSize(1.9);
      }
      @include sm {
        opacity: 0;
        display: none;
        margin-bottom: $gap-half-size;
      }
    }
  }
}

#page.login .contentPanel {
  background-image: url("/images/Malfarben.jpg");
}
#page.datenschulzerklarung .contentPanel {
  background-image: url("/images/Malen.jpg");
}
#page.kinder .contentPanel {
  background-image: url("/images/LP-04.jpg");
}
#page.kunstler .contentPanel {
  background-image: url("/images/Zeichnen.jpg");
}
.contentPanel {
  min-width: 260px;
  align-self: stretch;
  background-color: $white;
  z-index: 10;
  background-image: url("/images/left-column.webp");
  background-size: cover;
  background-position: 50%;
  min-height: 400px;
  max-height: 800px;
  flex-grow: 1;
  flex-shrink: 1;

  @include lg {
    margin-bottom: 0;
    padding: $gap-half-size 0;
    width: 100%;
  }

  @include sm {
    padding: $gap-half-size 0;
  }

  .contentPanelInner {
    @include displayFlex($flexDirection: column);
    gap: $gap-size-normal 0;
    @include lg {
      gap: 0 $gap-size-normal;
      flex-direction: row;
    }
  }
}
