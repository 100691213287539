@include lg {
  header .headerNavWrap {
    overflow-x: visible;
    visibility: hidden;
    transition: $header-transition-duration visibility;

    &::before {
      transform: translateX($header-nav-width-mobile + 2rem);
      @include pseudoElementContent($height: 6.25rem, $width: $header-nav-width-mobile, $top: 0, $right: 0);
      background-color: $primary;
      background: linear-gradient(to right, $primary 50%, $secondary 50%) left;
      background-size: 200%;
      background-position: left;
      transition: transform $header-transition-duration ease-out, 0.2s background-position 0s ease-out;
      z-index: 1;
    }

    &.visible {
      visibility: visible;
      transition: visibility;
      &::before {
        z-index: 0;
        transform: translateX(0px);
        transition: transform $header-transition-duration ease-out, 0.2s background-position 0s ease-out;
      }

      &.focus::before {
        background-position: right;
        transition: transform $header-transition-duration ease-out, 0.2s background-position 0s ease-out;
      }
    }

    &.focus {
      &::before {
        background-position: right;
        transition: transform $header-transition-duration ease-out, 0.2s background-position 0s ease-out;
      }
    }

    .headerNav {
      left: auto;
      position: absolute;
      top: 0;
      right: 0;
      height: 100vh;
      width: $header-nav-width-mobile;
      transform: translateX($header-nav-width-mobile + 2rem);
      transition: transform $header-transition-duration ease-out, 0.6s opacity $header-transition-duration;
      box-shadow: -2px 0px 25px $half-black;
      overflow-y: scroll;
      opacity: 0;

      &.open {
        opacity: 1;
        transform: translateX(0px);
        transition: transform $header-transition-duration ease-out, 0.2s background-position 0s ease-out;
      }

      .eshopMenu {
        padding: $gap-size-1x 0 2rem;
        margin-top: $gap-size-1x;
        @include displayFlex($justifyContent: center, $alignItems: flex-start, $flexDirection: column);
        gap: $gap-size-normal;

        .eshopMenuLogin {
          text-transform: capitalize;
          @include displayFlex($alignItems: center);

          svg {
            width: 20px;
            display: inline-flex;
            margin-right: $gap-half-size;
          }
        }

        li {
          width: 100%;

          .button {
            width: 100%;

            &:hover {
              background-color: $white;
              color: $black;
            }
          }
        }
      }

      nav {
        align-items: flex-start;
        flex-direction: column;
        padding: $header-nav-padding-mobile;

        .headerNavRight,
        .headerNavLeft {
          height: auto;
          width: auto; // 100px !important;
          margin-bottom: 0.5rem;
          padding: 0.5rem 0.9rem;
          overflow: visible;

          span {
            transform: skew(0, 0);
          }
        }

        .headerNavLeft {
          &:hover {
            color: $white;

            &::after {
              transform: scale3d(1, 1, 1);
              transition: transform 0.2s ease-out !important;
            }
          }

          &::after {
            background: none;
            background-color: $primary;
            transition: transform 0.3s ease-in;
            transform-origin: center;
          }

          &.activated {
            &::after {
              border: 1px solid $white;
              transform: scale3d(1, 1, 1) skew(0);
              width: 100%;
            }
          }
        }

        .headerNavRight {
          color: $white;
          margin-top: 3rem;

          &:hover {
            color: $black;
            &::after {
              transform: scale3d(1, 1, 1);
              transition: transform 0.2s ease-out !important;
            }
          }

          &::after {
            background: none;
            background-color: $secondary;
            transition: transform 0.3s ease-in;
            transform-origin: center;
          }

          &.activated {
            &::after {
              border: 1px solid $black;
              width: 100%;
              transform: scale3d(1, 1, 1) skew(0);
            }
          }
        }

        .headerNavCenter {
          flex-grow: 0;

          ul.mainMenu {
            transform: skew(0, 0);
            flex-direction: column;
            align-items: flex-start;

            li {
              &.active {
                &::before {
                  border-bottom-width: 1px;
                  border-bottom-color: $white;
                }
              }

              a {
                padding: 0.5rem 0.8rem;
                text-align: left;
              }
            }
          }
        }
      }
      &.category .headerNavLeft span,
      &.focus .headerNavRight span {
        transform: none;
      }
      &.focus {
        transition: transform $header-transition-duration ease-out, 0.2s background-position 0s ease-out,
          0.6s opacity 0.1s;
        .headerNavLeft {
          &:hover::after {
            border-color: $black;
          }
          &::after {
            transition: transform 0.2s ease-in;
            transform: translateZ(0);
          }
        }

        .headerNavLeft {
          color: $black;
        }

        nav ul.mainMenu li.active::before {
          border-bottom-color: $black;
        }
      }
    }
  }
}

@include xxs {
  header .headerNavWrap {
    &::before {
      width: $max-percentage;
      transform: translateX($max-percentage);
    }
    .headerNav {
      width: $max-percentage;
      transform: translateX($max-percentage);
    }
  }
}
