.productRating {
  @include displayFlex($alignItems: center, $justifyContent: flex-start);
  gap: 0 0.45rem;
  padding: 1.1rem 0.25rem;

  .rating {
    cursor: pointer;

    figure {
      aspect-ratio: auto;
    }

    svg {
      @extend %svg-star-rating-sizes-normal;
      animation: scales 1s ease-in-out;
    }
  }

  p {
    margin-bottom: 0;
    margin-left: 0.5rem;
  }
}
