.steps {
  @extend %steps;
  padding: 20px 20px 40px;
  display: block;

  @include lg {
    font-size: 18px;
    padding: 30px 20px 56px;
  }

  ul {
    @include displayFlex($alignItems: center, $justifyContent: space-between);

    @include lg {
      margin-bottom: 10px;
    }

    li {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      flex-grow: 0;
      height: 31px;
      padding: 0 0 0 45px;
      position: relative;
      z-index: 1;

      @include lg {
        text-align: center;
      }

      span {
        color: $black;
        font-weight: 500;

        @include lg {
          font-size: 0;
          color: transparent;
        }
      }

      &:before {
        position: absolute;
        top: 0;
        left: 0;

        @include lg {
          left: 50%;
          margin-left: -15px;
        }
      }

      &.divider {
        flex-grow: 1;
        counter-increment: none;
        padding: 0;
        min-width: 40px;

        @media screen and (max-width: 1100px) {
          width: 30%;
        }

        @include lg {
          width: auto;
        }

        &:before {
          display: none;
        }

        &:after {
          content: "";
          display: block;
          height: 2px;
          background: $primary;
          width: calc(100% - 40px);
          position: absolute;
          top: 50%;
          left: 20px;
          margin-top: -1px;
          transition: all 0.15s ease-in-out;
        }

        &.current {
          &:after {
            background: $black;
          }
        }
      }

      &.current {
        color: $secondary;
      }

      &:not(.current) {
        &:before {
          background-color: transparent;
          border: 1px solid $primary;
          color: $primary;
        }
      }
    }
  }

  + .btns {
    position: relative;
    z-index: 2;
    transform: translate(0%, -50%);
    margin-top: 0;
    display: flex;
    justify-content: space-around;

    @include lg {
      .btn {
        font-size: 12px;
        width: 185px;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
