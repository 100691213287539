@keyframes pulseIn {
  from {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  to {
    transform: scale(1);
  }
}

@keyframes pulseInWithOpacity {
  from {
    transform: scale(1);
    opacity: 0;
  }

  50% {
    transform: scale(1.4);
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes scaleOut {
  from {
    transform: scale(1);
    opacity: 1;
  }

  to {
    transform: scale(0);
    opacity: 0;
  }
}

@keyframes pulseOut {
  from {
    transform: scale(1);
  }

  50% {
    transform: scale(0.8);
  }

  to {
    transform: scale(1);
  }
}

@keyframes scales {
  from {
    transform: scale(1);
  }

  50% {
    transform: scale(0.8);
  }

  to {
    transform: scale(1);
  }
}

@keyframes videoIn {
  from {
    transform: perspective(260px) scale(0) rotateX(-20deg);
    transform-origin: 50% 50%;
  }
  to {
    transform: perspective(none) scale(1) rotateX(0);
  }
}

@keyframes videoOut {
  from {
    transform: perspective(none) scale(1) rotateX(0);
    transform-origin: 50% 50%;
    opacity: 1;
  }
  to {
    transform: perspective(260px) scale(0) rotateX(-20deg);
    opacity: 0;
  }
}

@keyframes scaleIcon {
  from {
    transform: scale3d(1, 1, 1);
  }
  to {
    transform: scale3d(1.2, 1.2, 1.2);
  }
}

@keyframes scaleIconOut {
  from {
    transform: scale3d(1.2, 1.2, 1.2);
  }
  to {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOutIn {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

@keyframes fadeOutIn2 {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes loadingSpinnerRight {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes headerBackgroundSlideIn {
  0% {
    background-position: left;
  }
  25% {
    background-position: right;
  }
  55% {
    background-position: right;
  }
  100% {
    background-position: left;
  }
}

@keyframes rollBadgeAfter {
  from {
    width: 0;
  }
  to {
    width: 5rem;
  }
}
