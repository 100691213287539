// Mixins

@mixin displayFlex(
  $alignItems: "",
  $justifyContent: "",
  $flexDirection: "",
  $flexWrap: "",
  $flexGrow: "",
  $flexShrink: ""
) {
  display: flex;

  @if $alignItems != "" {
    align-items: $alignItems;
  }

  @if $justifyContent != "" {
    justify-content: $justifyContent;
  }

  @if $flexDirection != "" {
    flex-direction: $flexDirection;
  }

  @if $flexWrap != "" {
    flex-wrap: $flexWrap;
  }

  @if $flexGrow != "" {
    flex-grow: $flexGrow;
  }

  @if $flexShrink != "" {
    flex-shrink: $flexShrink;
  }
}

@mixin pseudoElementContent($width: 100%, $height: 100%, $top: "", $right: "", $bottom: "", $left: "") {
  content: "";
  position: absolute;
  display: block;

  @if $width != "" {
    width: $width;
  }
  @if $height != "" {
    height: $height;
  }

  @if $top != "" {
    top: $top;
  }
  @if $right != "" {
    right: $right;
  }
  @if $bottom != "" {
    bottom: $bottom;
  }
  @if $left != "" {
    left: $left;
  }
}

@mixin transitionIn($property) {
  transition: $property 0.2s ease-in;
}

@mixin transitionOut($property) {
  transition: $property 0.2s ease-out;
}
