/* @mixin retina {
  @media only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min—moz-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2/1),
    only screen and (min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
    @content;
  }
} */

$screen-xxs: 340px;
// Small tablets and large smartphones (landscape view)
$screen-xs: 414px;
$screen-sm: 576px;
// Small tablets (portrait view)
$screen-md: 768px;
// Tablets and small desktops
$screen-lg: 992px;
// Large tablets and desktops
$screen-xlg: 1200px;
$screen-xl: 1440px;
// Extra large tablets and desktops
$screen-xxl: 2400px;

// smallest phones
@mixin xxs {
  @media (max-width: #{$screen-xxs}) {
    @content;
  }
}

@mixin xs {
  @media (max-width: #{$screen-xs}) {
    @content;
  }
}

// Small devices
@mixin sm {
  @media (max-width: #{$screen-sm}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (max-width: #{$screen-md}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (max-width: #{$screen-lg}) {
    @content;
  }
}

@mixin min-lg {
  @media (min-width: #{$screen-lg}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (max-width: #{$screen-xlg}) {
    @content;
  }
}

@mixin xlx {
  @media (max-width: #{$screen-xl}) {
    @content;
  }
}

@mixin min-xlx {
  @media (min-width: #{$screen-xl}) {
    @content;
  }
}

// Extra Extra large devices
@mixin xxl {
  @media (min-width: #{$screen-xxl}) {
    @content;
  }
}
