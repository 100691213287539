.catalogueSortFilters {
  flex: 1;
  flex-grow: 1;
  flex-shrink: 0;
  margin-right: 2.25rem;

  @include lg {
    margin-right: 0;
  }

  @include sm {
    .sortOpener {
      width: 100%;
      @include displayFlex($justifyContent: space-between, $alignItems: center);
      padding: $gap-half-size $gap-size-normal 0;

      h2 {
        margin-bottom: 0;
        @include sm {
          font-size: fontSize(1.9);
        }
      }

      .sortClose {
        cursor: pointer;
        display: inline-flex;
        position: relative;
      }
      img {
        transform: rotate(180deg);
        transition: transform 0.3s ease-in-out;
        width: 21px;

        @include sm {
          width: 15px;
        }
      }

      &.open {
        img {
          transform: rotate(0);
          transition: transform 0.2s ease-in;
        }
      }
    }
  }

  ul {
    @include displayFlex;
    gap: 0 1.8rem;
    width: 100%;
    justify-content: flex-end;

    @include lg {
      width: calc(100% - $gap-size-1x * 2);
      padding: $gap-half-size 0;
      margin: 0 $gap-size-1x;
      flex-wrap: wrap;
      gap: $gap-half-size $gap-size-normal;
      justify-content: flex-start;
    }

    @include sm {
      margin: 0;
      height: 0;
      overflow: hidden;
      opacity: 0;
      padding: 0;
      span {
        pointer-events: none;
      }

      &.visible {
        margin: 0 $gap-size-normal;
        height: auto;
        width: 100%;
        overflow: visible;
        opacity: 1;
        padding: $gap-half-size 0 $gap-size-normal;
        span {
          pointer-events: all;
        }
      }
    }

    li span {
      font-size: fontSize(1.7);
      position: relative;
      cursor: pointer;
      &::after {
        @include pseudoElementContent($bottom: -2px, $left: 0, $width: 0, $height: 2px);
        visibility: hidden;
        transition: width 0.2s ease-in-out;
      }
      &:hover {
        color: $black;
        &::after {
          transition: width 0.2s ease-in;
          background-color: $black;
          visibility: visible;
          width: 100%;
        }
      }
      &.active {
        color: $primary;
        &::after {
          width: 100%;
          background-color: $primary;
          visibility: visible;
        }
      }
    }
  }
}
