[aria-labelledby="modal-banner-video"] {
  @include displayFlex($alignItems: flex-start, $justifyContent: center);
  padding-top: 4rem;
  width: $max-percentage;
  height: $max-percentage;

  @include sm {
    align-items: center;
  }

  .modalContainer {
    z-index: 1;
    display: block;
    position: relative;
    top: 0;
    left: 0;
    width: 70%;

    @include sm {
      width: 90%;
    }
  }

  .videoWrapper {
    position: relative;
    &.paused .video-react {
      cursor: $play-icon 0 0, auto;
    }
    .video-react {
      cursor: $pause-icon 0 0, auto;

      .video-react-big-play-button {
        top: calc(50% - 90px / 2);
        left: calc(50% - 90px / 2);
      }
    }
  }
  > div:not(.videoWrapper) {
    z-index: -1;
    cursor: $close-icon 0 0, auto;
  }
}
