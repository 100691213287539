header .headerNavWrap {
  overflow-x: hidden;
  overflow-y: visible;

  .headerNav {
    left: -29px;
    width: calc(100% + 58px);
    position: relative;
    transform: skew(-29deg, 0);
    background-color: $primary;
    background: linear-gradient(to right, $primary 50%, $secondary 50%) left;
    background-size: 200%;
    background-position: left;
    height: $header-nav-height;
    transition: 0.2s background-position 0s ease-in, height $header-transition-duration ease-out;

    &.animateOnlyOnce {
      animation: headerBackgroundSlideIn 0.45s ease-out $header-transition-duration forwards;

      nav .headerNavCenter {
        ul.mainMenu li a {
          transition: $header-transition-duration color 0.2s ease,
            $header-transition-duration opacity 0.2s ease-out, padding $header-transition-duration ease-out;
        }
      }

      &.category .mainMenu a {
        animation: fadeOutIn 1s ease-out forwards !important;
      }
      &.focus .mainMenu a {
        animation: fadeOutIn2 1s ease-out forwards !important;
      }
    }

    nav {
      display: flex;
      align-items: center;
      height: 100%;

      .headerNavRight,
      .headerNavLeft {
        height: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;
        overflow: hidden;
        z-index: 10;
        display: flex;
        justify-content: center;
        position: relative;

        span {
          color: $white;
        }

        &::after {
          @include pseudoElementContent($top: 0, $left: 0);
          transition: transform 0.1s ease-out;
          z-index: -1;
        }

        span {
          transform: skew(29deg, 0);
          transition: 200ms all 0s cubic-bezier(0.84, 0.06, 0.52, 1.8);
        }
      }

      .headerNavLeft {
        width: 195px;

        &::after {
          background-color: $primary;
          transform-origin: bottom right;
          top: 0;
          left: 0;
        }

        &.activated {
          &::after {
            width: 200%;
            transition: width 0.2s ease-in;
            overflow: visible;
          }
        }
      }

      .headerNavRight {
        width: 195px;
        span {
          color: $black;
        }

        &::after {
          background-color: $secondary;
          transform-origin: bottom left;
        }

        &.activated {
          &::after {
            width: 200%;
            transition: width 0.2s ease-in;
            overflow: visible;
          }
        }
      }

      .headerNavCenter {
        flex: 1;
        display: flex;
        justify-content: center;

        ul.mainMenu {
          display: flex;
          text-align: left;
          gap: 0 1.5rem;
          transform: skew(29deg, 0);

          li {
            position: relative;
            overflow: hidden;

            &.active {
              &::before {
                transform: translateY(0);
                visibility: visible;
                transition: transform 0.3s ease-in 0.3s;
                background-color: $half-white;
                border-bottom: none;
              }

              &::after {
                content: none;
              }
            }

            a {
              padding: 0.75rem 0;
              min-width: 100px;
              text-align: center;
              color: $white;
              display: inline-block;
              position: relative;
              z-index: 2;
              // animation: fadeOutIn 1s ease-out forwards;
              transition: $header-transition-duration color 0.2s ease-out,
                padding $header-transition-duration ease-out;

              &:focus-within,
              &:focus {
                outline-offset: -1px;
              }
            }

            &::before {
              @include pseudoElementContent($bottom: 0, $left: 0);
              z-index: 0;
              background-color: $half-white;
              transform: translateY(100px);
              border-bottom: 2px solid $half-white;
              transition: transform 0.2s ease-out;
            }

            &:after {
              @include pseudoElementContent($bottom: 0, $left: 0, $height: "" /* , $width: 0 */);
              border-bottom: 2px solid;
              border-bottom-color: $half-white;
              visibility: hidden;
              transform: scale3d(0, 0, 0);
              transition: transform 0.2s ease-out, 0.2s visibility;
            }

            &:hover {
              &::after {
                visibility: visible;
                transition: transform 0.2s ease-in;
                transform: scale3d(1, 1, 1);
              }
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
    &.category .headerNavLeft span,
    &.focus .headerNavRight span {
      transform: skew(29deg, 0) scale3d(1.1, 1.1, 1.1) translateZ(0);
      transition: transform 400ms cubic-bezier(0.84, 0.06, 0.52, 1.8);
    }

    &.focus {
      background-position: right;
      transition: 0.2s background-position 0.2s ease-in, height $header-transition-duration ease-in-out;

      .headerNavRight,
      .headerNavCenter a {
        color: $black !important;
        transform: translateZ(0);
      }

      .headerNavCenter li::after {
        border-bottom-color: $half-black !important;
      }

      .headerNavLeft {
        &::after {
          transition: transform 0.2s ease-in;
          transform: translateZ(0);
        }
      }
    }
  }
}
