header .headerTop {
  @include displayFlex($justifyContent: space-between, $alignItems: center);
  position: relative;
  top: 0;
  left: 0;
  padding: 1.3rem 2.56rem 1rem;
  @include transitionOut(padding);

  &.searchExpanded {
    height: $max-percentage;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: $max-percentage;
      height: $header-height-mobile;
      background-color: $white;
      z-index: 11;
    }

    .headerTopLeft,
    .eshopMenu,
    .mobileMenuOpener,
    .headerTopRight {
      display: none;
    }
  }

  .headerTopLeft {
    width: 95px;
    height: 100%;

    .headerLogo {
      display: block;
      width: 76px;
      margin: 5px 0;
      transition: width $header-transition-duration ease-out;
      svg {
        fill: $black-logo;
      }
    }
  }

  .search {
    transition: height $header-transition-duration ease-out, margin-right 0.4s ease-out,
      width $header-transition-duration ease-out;
  }

  .headerTopRight {
    @include displayFlex($justifyContent: flex-end, $alignItems: center);
    min-width: 90px;

    .eshopMenu {
      @include displayFlex($justifyContent: center, $alignItems: center);
      column-gap: 1.8rem;
      transition: column-gap $header-transition-duration ease-out;

      a {
        border-bottom: 2px solid transparent;
        font-weight: 500;
        position: relative;
        display: block;
        padding: 10px 0 5px;

        &.eshopMenuBasket {
          svg {
            fill: $black;
            transition: width $header-transition-duration ease-out, fill 0.2s ease-out;
            width: 32px;
          }

          &:hover {
            svg {
              transition: fill 0.2s ease-out;
              fill: $primary;
            }
          }

          &.active {
            svg {
              fill: $primary;
            }
          }

          .eshopBasketCount {
            @include displayFlex($alignItems: center, $justifyContent: center);
            position: absolute;
            top: -12px;
            right: -5px;
            width: 25px;
            height: 25px;
            background-color: $primary;
            border-radius: $half-percentage;
            color: $white;
            font-size: fontSize(1.5);
            transition: width $header-transition-duration ease-out,
              height $header-transition-duration ease-out, top 0.3s ease-out, 0.2s opacity, 0.2s visibility;
            opacity: 0;
            visibility: hidden;

            &.visible {
              opacity: 1;
              visibility: visible;
            }

            &.pop {
              animation: pulseIn 0.2s ease-out;
            }
            &.remove {
              animation: scaleOut 0.2s ease-out;
            }
          }
        }

        &.eshopMenuLogin {
          &:hover {
            svg {
              fill: $primary;
            }
          }
          &.withName {
            @include displayFlex($alignItems: center);
            column-gap: $gap-half-size;
            span {
              text-transform: capitalize;
              order: 0;
            }
          }
          svg {
            fill: $black;
            transition: width $header-transition-duration ease-out, fill 0.2s ease-out;
            width: 24px;
            margin-bottom: -1px;
            order: 1;
          }

          &.active {
            svg {
              fill: $primary;
              transition: fill 0.2s ease-out;
            }
          }
        }
      }

      li {
        position: relative;

        &.active {
          a {
            border-bottom: 2px solid $white;
          }
        }

        .eshopMenuBasketDropdown {
          position: absolute;
          top: 40px;
          right: -2rem;
          width: $header-eshop-menu-dropdown-width;
          border: 1px solid $grey;
          z-index: 25;
          min-height: 1vh;
          max-height: calc(100vh - 3rem);
          height: auto;
          background-color: $white; //lighten($secondary, 40);
          box-shadow: $shadow;
          padding: 1.25rem 0;
          overflow-y: scroll;

          .basketDropdown {
            @include displayFlex($flexDirection: column);
            row-gap: $gap-half-size;
            overflow-y: scroll;
            height: 100%;
            padding: 5px $gap-size-normal;

            .basketDropdownRow a {
              @include displayFlex($alignItems: center, $justifyContent: space-between);
              column-gap: $gap-three-quarter-size;
              font-size: fontSize(1.5);

              figure {
                margin: initial;
                width: 30%;
                height: 50px;
              }

              div p {
                white-space: nowrap;
              }
            }
            .button {
              float: right;
              padding: 0.4rem 2rem;
            }
          }
        }

        .eshopMenuDropdown {
          display: none;
          flex-direction: column;
          padding: 1rem 1rem;
          position: absolute;
          z-index: 100;
          top: 40px;
          right: -2rem;
          background-color: $white;
          box-shadow: $shadow;

          &.open {
            display: flex;
          }

          &.userPannel {
            .button {
              margin-bottom: 1.3rem;
              white-space: pre;
            }
          }

          li {
            min-width: 220px;
            width: $max-percentage;
            text-align: left;
            a {
              padding: 0.5rem 0.5rem;
            }
            &.indented {
              margin-top: 0.5rem;
              a {
                padding-top: 1rem;
                border-top: 1px solid grey;
              }
            }
          }

          &:hover {
            display: flex;
          }

          hr {
            margin: 1.3rem 0;
          }
        }
      }
    }
  }
}
