.basketItems {
  display: table;
  border-collapse: collapse;
  width: 100%;

  &Header {
    display: table-row;

    @include lg {
      display: none;
    }
  }

  + .basketSummary {
    margin-top: $gap-size-1x;
  }

  .td {
    color: $grey-dark;
    display: table-cell;
    padding: 10px $gap-size-normal;
    text-align: left;
    vertical-align: middle;
    border-bottom: 1px solid $grey;
    font-size: fontSize(1.75);
    width: auto;

    @include lg {
      font-size: 11px;
      padding: 0 !important;

      &.name,
      &.image {
        border-bottom: none;
      }

      &.price,
      &.count,
      &.sub-total,
      &.grand-total {
        border-bottom: 1px solid $grey;
        line-height: 25px;
        padding: 5px 0 5px 0;
      }
    }

    &.empty {
      padding-left: 0;
      padding-right: 0;
      width: 0;

      @include lg {
        display: none;
      }
    }

    .hint {
      display: none;
      border-bottom: 1px solid $grey;
      padding: 0 0 5px 0;
      margin: 0 0 5px 0;

      @include lg {
        display: block;
      }
    }

    &.image {
      padding-right: 0;
      width: 76px;
      padding: 5px 10px 5px 16px;

      @include lg {
        width: 60px;
        order: 1;
        margin-bottom: 10px;
      }

      figure {
        text-align: center;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        vertical-align: middle;

        @include lg {
          width: auto;
        }
      }

      img {
        width: auto;
        max-height: 50px;
        max-width: 50px;
        height: auto;
        object-fit: contain;
      }
    }

    &.name {
      width: auto;

      @include lg {
        width: calc(100% - 90px);
        padding: 0 40px 0 20px;
        order: 2;
        font-size: 14px;
        margin-bottom: 10px;
      }
    }

    &.price {
      width: 120px;

      @include lg {
        width: calc(50% - 64px);
        padding-left: 5px;
        order: 3;

        .hint {
          width: calc(100% + 5px);
          margin-left: -5px;
          padding-left: 5px;
        }
      }
    }

    &.count {
      width: 140px;

      @include xl {
        width: 180px;
      }

      @include lg {
        width: 104px;
        padding-right: 5px;
        order: 5;

        .hint {
          width: calc(100% + 5px);
        }
      }

      .quantity {
        @include displayFlex($justifyContent: flex-start);

        button {
          padding: 0.45em;

          svg {
            width: 20px;
          }
        }

        input {
          width: 35px;
          text-align: center;
        }

        @include lg {
          justify-content: space-between;
        }
      }
    }

    &.sub-total {
      width: 120px;
      @include xl {
        width: 150px;
      }
      @include lg {
        width: calc(50% - 40px);
        order: 4;
      }
    }

    &.grand-total {
      width: 100%;
    }

    &.actions {
      width: 60px;
      border-bottom: none;
      @include lg {
        position: absolute;
        top: 0;
        right: 0;
        padding: 0;
        width: 30px;
      }

      button {
        background: $white;
        padding: 0;

        svg {
          width: 25px;
          height: 25px;
          fill: $grey-dark;

          @include lg {
            width: 30px;
            height: 30px;
          }
        }

        &:hover {
          svg {
            fill: $primary;
          }
        }
      }
    }
  }

  .basketItem {
    display: table-row;
    position: relative;

    @include lg {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      + .basketItem {
        margin-top: 30px;
      }
    }
  }
}
