.catalogueListBanner {
  @include displayFlex($alignItems: flex-start, $flexDirection: column, $justifyContent: center);
  padding: 0 $gap-size-1x;
  background-blend-mode: multiply;
  width: 100%;
  word-break: break-all;
  position: relative;

  @include lg {
    height: auto;
    padding: $gap-size-1x $gap-size-2x;
  }
  @include sm {
    padding: $gap-size-1x;
  }

  h2 {
    font-size: fontSize(5);
    color: $white;
    letter-spacing: 1.67;

    @include lg {
      font-size: fontSize(4);
    }
    @include sm {
      font-size: fontSize(3.5);
    }
  }
  p {
    color: $white;
    max-width: 900px;
    margin-bottom: $gap-size-normal;
  }
  .overlay {
    background: rgba($black, 0.15) center / cover no-repeat;
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
    z-index: -1;
  }
  img {
    z-index: -2;
    object-fit: cover;
  }
}
