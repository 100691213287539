.toast {
  min-width: 150px;
  width: auto;
  min-height: 25px;
  max-width: 800px;
  z-index: 1000;
  position: fixed;
  top: 30px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  border-radius: 3px;
  padding: $gap-half-size;
  opacity: 0;
  transform: translateY(-250px);
  transition: opacity 0.3s ease-in, transform 0.5s ease-in, 0.5s background-color;
  background-color: $toastSuccessColor;
  border-radius: 10px;
  &.show {
    transform: translateY(0);
    opacity: 1;
    transition: opacity 0.3s ease-in, transform 0.5s ease-in;
  }

  &.error {
    background-color: $toastErrorColor;
  }

  .toastContent {
    @include displayFlex($alignItems: center, $justifyContent: space-between);
    padding: 0.6rem 1em;
    color: $white;

    a {
      color: $white;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .close {
    cursor: pointer;
    background-color: $white;
    border-radius: 50%;
    padding: 4px;
    text-align: center;
    @include displayFlex($alignItems: center, $justifyContent: center);

    &:hover {
      background-color: $white;
    }

    svg {
      fill: $black;
      margin: auto;
      width: 7px;
      height: 7px;
    }
  }

  p {
    margin-bottom: 0;
    color: $white;
    line-height: 1.3;
  }
}
