@import "header_top";
@import "header_top_mobile";
@import "header_nav";
@import "header_nav_mobile";
@import "header_shrink";
@import "header_shrink_mobile";

header {
  @include displayFlex($flexDirection: column);
  flex-shrink: 0;
  z-index: 20;
  background: $white;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  transition: padding $header-transition-duration ease-out;
  max-width: $screen-xl;
  margin: 0 auto;

  @include lg {
    overflow: hidden;
    &.navOpen {
      overflow: visible;
    }
  }

  // Menu opener
  @include lg {
    .headerTop .mobileMenuOpener.open {
      z-index: 0;
    }
    .headerNav .mobileMenuOpener {
      @include lg {
        position: absolute;
        top: 47px;
        right: 1.5rem;
      }
      @include sm {
        top: 33px;
        right: 1rem;
      }
    }
  }

  .mobileMenuOpener {
    cursor: pointer;
    width: 30px;
    height: 26px;
    transition: width 0.4s ease-in;
    display: block;
    z-index: 2;

    &:hover {
      [class*="icon-"] {
        background-color: $primary;
      }
    }

    [class*="icon-"] {
      position: relative;
      left: 0;
      top: 0;
      width: 100%;
      height: 3px;
      background-color: $black;
      transition: all 0.2s ease-in;
    }

    &.open [class*="icon-"] {
      transition: 0.2s transform 0.1s ease-in, 0.2s opacity 0.1s ease-in;
    }

    &.invert [class*="icon-"] {
      background-color: $white;
    }

    .icon {
      &-1 {
        animation-delay: 100ms;
      }
      &-2 {
        transform: translateY(8px);
      }
      &-3 {
        transform: translateY(16px);
        animation-delay: 250ms;
      }
    }

    &.open {
      .icon {
        &-1 {
          transform: rotate(-45deg) translate(-8px, 7px);
        }
        &-2 {
          opacity: 0;
        }
        &-3 {
          transform: rotate(45deg) translate(4px, 4px);
        }
      }
    }
  }
}
