.banner + .breadCrumbsWithSort {
  .breadcrumbs {
    @include lg {
      border-bottom: 1px solid $grey;
    }
  }
}

.breadcrumbs {
  flex-grow: 0;
  flex-shrink: 0;

  @include lg {
    flex-grow: 1;
    width: $max-percentage;
    padding-bottom: 5px;
  }

  .breadcrumbsList {
    @include displayFlex($alignItems: center);
    padding: 0 2.5rem;

    @include lg {
      padding: 0 $gap-size-1x;
    }
    @include sm {
      padding: 0 $gap-size-normal;
    }

    .breadcrumbsListItem {
      text-transform: capitalize;
      @include displayFlex($alignItems: center);

      &:not(:first-child) {
        margin-left: 0.2rem;
        margin-top: 0.2rem;

        @include lg {
          margin-top: 0;
        }
      }

      a {
        @include displayFlex($justifyContent: flex-start, $alignItems: center);
        font-size: fontSize(1.5);
        line-height: initial;
        padding: 5px;
        position: relative;
        text-transform: capitalize;
      }

      &:not(:last-child)::after {
        content: $slash;
        margin-left: 0.2rem;
        line-height: 1.9;
      }

      &:first-child a::after {
        bottom: 0;
      }

      a {
        &::after {
          @include pseudoElementContent($bottom: 4px, $left: 0, $width: 0, $height: 1px);
          background-color: $primary;
          z-index: 10;
          visibility: hidden;
          @include transitionOut(width);
        }
        &:hover {
          svg {
            fill: $primary;
            @include transitionIn(fill);
          }

          &::after {
            visibility: visible;
            width: 100%;
            @include transitionIn(width);
          }
        }
      }
    }
  }
}

:not(.breadCrumbsWithSort .breadcrumbs) {
  .breadcrumbs {
    position: sticky;
    top: 0;
    left: 0;
    background-color: $white;
    z-index: 10;
  }
}
