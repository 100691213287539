* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: top;

  :focus-visible {
    outline-offset: 1px;
    outline-style: auto;
    outline: $secondary solid 1px !important;
    border-radius: 4px !important;

    &.headerNavLeft {
      outline-offset: -5px;
    }
    &.headerNavRight {
      outline-offset: -5px;
      outline: $primary solid 1px !important;
    }
  }
}

html {
  min-height: $max-percentage;

  @media (prefers-reduced-motion: no-preference) {
    scroll-behavior: smooth;
  }

  body {
    min-height: $max-percentage;
    font-size: 14px;
    line-height: $line-height;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    font-family: $font-family;
    color: $black;
    background: $white;
    opacity: 0;
    transition: opacity 0.15s ease-in;

    &.loaded {
      opacity: 1;
      transition: opacity 0.15s ease-in;
    }

    #__next {
      min-height: $max-percentage;
      height: $max-percentage;
      position: relative;

      #page {
        z-index: 11;
        position: relative;
        width: $max-percentage;
        min-height: 100vh;
        @include displayFlex($flexDirection: column);
        max-width: $screen-xl;
        margin: 0 auto;

        main {
          flex-grow: 1;

          @include lg {
            .br {
              display: none;
            }
          }

          .container {
            width: $max-percentage;
            @include displayFlex($flexDirection: column);
            padding: 0 2.3rem;

            @include lg {
              padding: 0 $gap-size-1x;
            }
            @include sm {
              padding: 0 $gap-size-normal;
            }
          }

          .content {
            @include displayFlex;
            padding: 2.5rem 2.5rem 0;

            @include lg {
              flex-direction: column;
              padding: 1.5rem $gap-size-1x 0;
            }

            @include sm {
              padding: 1.5rem $gap-size-normal 0;
            }

            &.uberKin {
              section {
                margin: 1rem auto;
                @include lg {
                  margin: 0.5rem auto;
                }
              }
            }

            .contact {
              @include displayFlex($alignItems: flex-start, $justifyContent: space-between);
              column-gap: 1rem;
              border-top: 1px solid $grey-light;
              margin-top: 1rem;
              padding-top: 1rem;
              @include md {
                margin-top: 0rem;
                padding-top: 0.5rem;
              }

              @include md {
                flex-direction: column;
              }

              form {
                flex-basis: 60%;

                @include md {
                  flex-basis: 100%;
                  width: 100%;
                }
                fieldset {
                  border-top: 0;
                }
              }
              address {
                flex-basis: 40%;
                text-align: left;
                padding-top: 1rem;

                margin-left: 5%;
                @include lg {
                  p {
                    margin-bottom: $gap-half-size;
                  }
                }
                @include md {
                  margin-left: 0%;
                }
              }
            }

            &.withoutPanel {
              flex-direction: column;

              section:first-child {
                margin-top: 0;
              }

              @include lg {
                .twoColumnsImageText {
                  .rightCol {
                    margin-top: 0;
                    padding: 0;
                  }
                  .leftCol {
                    margin-top: 1.5rem;
                  }
                }
              }
            }

            .contentPage {
              width: 80%;
              padding-left: 3.75rem;

              @include lg {
                width: $max-percentage;
                padding-left: 0;
                flex-direction: column;
                margin-top: $gap-size-normal;
              }
              .wpContent {
                p {
                  margin: 0 0 2rem;
                }
              }
            }

            :not(.detail) section:not(:last-child) {
              margin-bottom: 3.5rem;

              @include lg {
                margin-bottom: 3rem;
              }
            }

            article {
              margin: 0.6rem 0 3.75rem;

              p {
                font-size: fontSize(2.1);
                letter-spacing: -0.53px;
                line-height: $line-height-big;
                margin-bottom: $gap-size-3x;

                &:last-child {
                  margin-bottom: 0;
                }

                @include lg {
                  margin-bottom: $gap-size-1x;
                }
              }
            }
          }
        }
      }
    }
  }
}

svg:not(:root) {
  overflow: visible;
}

footer,
section,
main,
figure {
  position: relative;
  margin: auto;
  width: $max-percentage;
  display: block;
}

section {
  &.textWithProducts {
    margin-top: 1.5rem !important; // this needs to be specific
  }
  &.twoColumnsImageText,
  &.twoColumnsFill,
  &.twoColumnsImages,
  &.twoColumnsBgImageText,
  &.textWithProducts {
    @include displayFlex;
    margin-top: $gap-size-1x;
    min-height: $section-height;
    @include xlx {
      margin-top: $gap-size-normal;
    }
    @include lg {
      margin-top: 0;
    }
    .leftCol {
      width: $half-percentage;
    }
    .rightCol {
      width: $half-percentage;
    }

    p {
      line-height: $line-height;
      font-size: fontSize(2);
      letter-spacing: -0.5px;
    }

    @include xlx {
      flex-direction: column;
      .rightCol {
        margin-top: $gap-size-1x;
      }
      .leftCol,
      .rightCol {
        width: $max-percentage;
      }
    }
  }

  &.products {
    @include displayFlex($flexDirection: column);
    overflow: hidden;
    padding: 0 0.4rem;
    margin-top: $gap-size-1x;

    @include xlx {
      padding: 0 $gap-size-2x;
    }
    @include lg {
      padding: 0 $gap-size-1x;
    }
    @include sm {
      padding: 0 $gap-size-normal;
    }

    .productsRow {
      @include displayFlex($justifyContent: space-between);
      padding: 1px;
      gap: 0 3.1rem;
      @include xl {
        flex-wrap: wrap;
        gap: 2rem 1.1rem;
      }

      .catalogueListItem {
        width: 23%;
        @include xl {
          width: 48%;
        }
        @include md {
          width: $max-percentage;
        }

        .listItemAmount .listItemAmountInner {
          justify-content: space-evenly;
          gap: unset;
        }
      }
    }
  }

  &.textWithProducts {
    @include xlx {
      flex-direction: column;
    }
    .leftCol {
      width: 30%;
      padding: 1.4rem 3.3rem 0 3.1rem;

      @include xlx {
        width: $max-percentage;
        padding: 0 $gap-size-2x;
      }
      @include lg {
        padding: 0 $gap-size-1x;
      }
      @include sm {
        padding: 0 $gap-size-normal;
      }
    }

    .rightCol {
      @include displayFlex($flexDirection: column);
      width: 70%;
      padding: 0.8rem 0 0;

      @include xlx {
        width: $max-percentage;
        padding: 0 $gap-size-2x;
      }
      @include lg {
        padding: 0 $gap-size-1x;
      }
      @include sm {
        padding: 0 $gap-size-normal;
      }

      .productRow {
        @include displayFlex($justifyContent: space-between);

        @include lg {
          flex-wrap: wrap;
          row-gap: $gap-size-1x;
        }

        @include md {
          flex-wrap: nowrap;
          flex-direction: column;
          row-gap: $gap-size-1x;
          .catalogueListItem {
            width: 100%;
          }
        }

        @include min-lg {
          .catalogueListItem {
            width: 30%;
          }
        }
      }
    }
  }

  &.fullWidthText {
    max-width: $text-max-width;
    @include displayFlex(center, center);

    h2 {
      font-size: fontSize(4);
      text-align: center;
      letter-spacing: -1px;
      line-height: 1.35;
    }
  }

  &.twoColumnsTexts {
    @include displayFlex($justifyContent: space-between);

    @include lg {
      flex-direction: column;
    }

    &.invertBg {
      background-color: $grey-dark;
      color: $white;
      padding: 3.5rem 2rem 3.5rem 3.75rem;
      margin-bottom: $gap-size-1x;

      padding: 2.5rem;
      @include lg {
        padding: 1.5rem;
      }

      p {
        margin: 0;
      }
    }

    .leftCol,
    .rightCol {
      width: 45%;
      @include lg {
        width: $max-percentage;
      }
    }

    .rightCol {
      @include lg {
        margin-top: 2.5rem;
      }
    }
  }

  &.fullWidthImage {
    @include displayFlex;
    background: $sectionFullImage no-repeat top right / cover;
    width: $max-percentage;
    height: 560px;
    margin-bottom: $gap-size-1x;
  }

  &.twoColumnsWithImageAndText {
    @include displayFlex($justifyContent: space-between);
    min-height: 292px;
    @include lg {
      flex-direction: column;
    }

    .leftCol {
      width: $half-percentage;
      padding-right: 6.6rem;
      @include lg {
        width: $max-percentage;
        padding-right: 0;
      }
    }

    .rightCol {
      width: $half-percentage;
      min-height: 290px;
      background: center / cover no-repeat $sectionRightImage;
      @include lg {
        width: $max-percentage;
      }
    }
  }
}

table {
  width: $max-percentage;
  border: 1px solid $grey-mid-dark;
  tr {
    border-bottom: 1px solid $grey-mid-dark;
    td {
      padding: 1.4rem 1.6rem;
      width: $half-percentage;
      border-right: 1px solid $grey-mid-dark;
      &:last-child {
        border-right: 0;
        text-align: right;
      }
    }
  }
}
.table {
  overflow-x: scroll;
  table {
    width: auto;
    border: 0;
    border-collapse: separate;
    border-spacing: 2px;
    background-color: $white;
    caption {
      text-align: left;
      div {
        @include displayFlex($alignItems: center, $justifyContent: space-between);
      }
    }

    tr {
      border-bottom: 0;

      th,
      td {
        border-collapse: separate;
        background-color: $grey-lighter;
        padding: 0.8rem 1.6rem 0.8rem 0.8rem;
        width: $half-percentage;
        border-right: 0;

        &:last-child {
          border-right: 0;
          text-align: left;
        }
      }
    }
  }
}

.user-account,
.orderDetail {
  .table table {
    width: 100%;

    th {
      text-align: left;
    }
    td {
      width: auto;
      &:last-child {
        text-align: center;
      }
      button {
        padding: 0.35rem 1.1rem;
      }
    }
  }
}

.line {
  display: grid;
  gap: 1rem;
  grid-template-rows: repeat(auto-fit, 1fr);
  grid-template-columns: repeat(auto-fill, minmax(22%, 1fr));

  @include lg {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}

.error {
  color: red;
}
.success {
  color: green;
}

.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
  word-break: normal;
}

#routeLeaveDialog {
  position: fixed;
  width: $max-percentage;
  height: $max-percentage;
  z-index: 1000;
  background: transparent;
  place-items: center;
  background: rgba(0, 0, 0, 0.25);

  &[open] {
    display: grid;
  }

  .dialogInner {
    width: 350px;
    height: 170px;
    @include displayFlex($alignItems: center, $justifyContent: center, $flexDirection: column);
    background-color: $white;
    text-align: center;

    p:nth-child(2) {
      margin-bottom: 0;
    }

    .dialogControls {
      padding-top: $gap-size-normal;
      @include displayFlex($alignItems: center);
      column-gap: $gap-size-1x;

      button {
        min-width: 70px;
      }
    }
  }
}
