.eshop {
  .contentHeader {
    @include displayFlex($alignItems: center, $justifyContent: space-between);
    margin-top: $gap-size-1x;

    @include lg {
      align-items: flex-start;
    }

    .contentHeaderActions {
      @include displayFlex($alignItems: stretch, $justifyContent: space-between, $flexGrow: 1);
      flex: 1 0 auto;
      column-gap: $gap-size-1x;

      @include lg {
        button:first-child {
          display: none;
        }
      }
    }
  }

  .basket {
    @include displayFlex($flexGrow: 1, $flexDirection: column);
    margin-top: $gap-size-2x;

    &.empty {
      text-align: center;

      h2 {
        text-transform: uppercase;

        &:before {
          content: $non-breaking-space;
          display: inline-block;
          width: 32px;
          vertical-align: middle;
          margin: -5px 5px 0 0;
          font-size: inherit;
          line-height: inherit;
          background: center/contain no-repeat $exclamation-mark-icon;
        }
      }
    }

    .basketSummary {
      position: relative;
    }

    .basketContinue {
      margin-top: $gap-size-2x;
      width: $max-percentage;

      &.continue {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        position: relative;
        margin-top: 0;
      }

      @include lg {
        display: none;
        bottom: 0;
        margin-top: 0;

        .shopping-options {
          margin-top: 0;
        }

        &.showMobile {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 10;
        }
      }
    }
  }

  > .title {
    margin-top: $gap-size-1x;
    + .line {
      margin-top: $gap-size-1x;
      + .tips {
        margin-top: $gap-size-4x;
      }
    }
    .large {
      font-size: 3rem;
      line-height: 3rem;
    }
  }

  .basket-cols {
    margin-top: $gap-size-2x;
  }
}

.btns-prev-next,
.form-btns {
  @include displayFlex($alignItems: flex-start, $justifyContent: space-between);
  margin: 20px 0 0;
  width: $max-percentage;

  &.single {
    justify-content: flex-end;
  }

  @include lg {
    flex-direction: column;
    align-items: stretch;
    row-gap: $gap-half-size;

    .button {
      text-align: center;
    }
  }
}

.form-btns {
  .btn {
    width: auto;
    flex-basis: auto;
  }
}

.orderSummary {
  .basket-cols {
    .basket-col {
      &:nth-child(1) {
        flex-basis: calc(30% - $gap-size-normal);
      }
      &:nth-child(2) {
        flex-basis: calc(70% - $gap-size-normal);
      }
    }

    .formRow {
      @include displayFlex($alignItems: center, $justifyContent: flex-start);
      input[type="checkbox"] + label,
      input[type="radio"] + label {
        width: auto;

        + .e {
          margin: 0 0 0 auto;
        }
      }
    }
  }
}

.basket-cols {
  @include displayFlex($alignItems: flex-start, $justifyContent: space-between);

  @include lg {
    flex-direction: column;
    align-items: stretch;
    margin-top: $gap-size-1x;
  }

  .form {
    & + .form {
      margin-top: $gap-size-1x;
    }
  }

  .basket-col {
    flex-basis: calc($half-percentage - $gap-size-1x);
    @include lg {
      flex-basis: $max-percentage !important;

      + .basket-col {
        margin-top: $gap-size-1x;
      }
    }

    .form {
      margin: 0;
      width: $max-percentage;
      max-width: $max-percentage;
      @include lg {
      }
    }
  }
}

.shopping-options {
  @include displayFlex($alignItems: flex-start, $justifyContent: space-between);
  flex-basis: calc($half-percentage - $gap-size-1x);
  position: relative;

  @include lg {
    flex-direction: column;
    flex-basis: $max-percentage;
    align-items: center;
    justify-content: center;
  }

  .form {
    border: 1px solid $grey-light;
    display: block;
    margin: initial;
    max-width: calc($half-percentage - 28px);

    @include lg {
      background-color: $secondary !important;
      flex-basis: $max-percentage;
      max-width: $max-percentage;
      border: none;

      &.user-login {
        display: none;
      }
    }

    form {
      > h1,
      > p {
        text-align: center;
      }
      > p {
        color: $grey-dark;
      }
    }
  }

  .close {
    position: absolute;
    top: 12px;
    right: 23px;
    display: none;

    svg {
      width: 14px;
    }

    @include lg {
      display: block;
      z-index: 1;
      height: auto;
    }
  }

  .user-with-login {
    display: none;

    @include lg {
      display: block;
    }
  }

  @include lg {
    flex-direction: column;
    padding: 20px 0;
    background: $secondary;
    margin-top: $gap-size-3x;

    .form {
      background: $secondary;
      width: $max-percentage;
      padding-left: #{2 * $gap-size-normal};
      padding-right: #{2 * $gap-size-normal};

      &.user-login {
        display: none;
      }
    }

    .close {
      display: block;
    }
  }
}

.thankYou {
  @include displayFlex($flexDirection: column, $alignItems: center, $justifyContent: center);
  height: 100%;
  position: relative;

  h2 {
    text-align: center;
    margin-top: $gap-size-1x;
  }

  p {
    margin-bottom: $gap-size-1x;
  }
  #drawing_canvas {
    top: -200px;
    right: unset;
    left: 50%;
    transform: translate(-50%, 0);
  }
}
