.breadCrumbsWithSort {
  @include displayFlex($justifyContent: space-between, $alignItems: center);
  position: sticky;
  top: $header-height-shrink;
  left: 0;
  z-index: 15;
  background: $white;
  padding: 5px 0;
  box-shadow: 0 4px 2px -2px rgba(95, 31, 31, 0.22);

  @include lg {
    top: $header-height-mobile-shrink;
    flex-direction: column;
    align-items: flex-start;
    padding: 5px 0 0;
    box-shadow: none;
  }
  @include sm {
    top: $header-height-mobile-shrink-sm;
    padding: 5px 0 6px;
  }

  &.hidden {
    opacity: 0;
    transition: transform 0.25s ease-in, opacity 0s ease-in 0.25s, visibility 0s linear 0.25s,
      height 0s linear 0.25s, padding 0s linear 0.25s;
    visibility: hidden;
    height: 0;
    padding: 0;
    transform: translateY(-50px);

    &.show {
      opacity: 1;
      transition: opacity 0.25s ease-out, transform 0.25s ease-out;
      visibility: visible;
      height: auto;
      transform: translateY(0);
    }
  }
}
