svg {
  display: block;
  @extend %svg-fill-transition;
}

a {
  color: $black;
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  @include transitionOut(color);

  &:not(.button):hover {
    color: $primary;
  }
}

p {
  margin-bottom: 0.3rem;
  letter-spacing: 0.36px;
  line-height: $line-height;

  @include lg {
    font-size: fontSize(1.875) !important;
  }
}

span {
  svg {
    display: inline;
  }
}

strong {
  font-family: $font-family-heading;
  font-weight: normal;
}

hr {
  border: none;
  height: 1px;
  color: $black;
  background-color: $black;
  margin: 0;
}

button,
[role="button"],
input[type="submit"],
.button {
  font-size: fontSize(2);
  font-family: $font-family;
  padding: 0.65rem 1.1rem;
  background-color: $black;
  color: $white;
  letter-spacing: -0.5px;
  transition: background-color 0.2s ease-in;
  cursor: pointer;

  @include lg {
    font-size: fontSize(1.9);
    padding: $gap-half-size $gap-three-quarter-size;
  }

  &:hover:not(.subLink) {
    transition: background-color 0.2s ease-in;
    background-color: $primary;
  }

  &.secondary {
    background-color: $white;
    color: $black;
    border: 1px solid $black;
    &:hover {
      background-color: $primary;
      color: $white;
      border-color: $primary;
    }
  }

  &.subLink {
    position: relative;

    span {
      position: relative;
      z-index: 10;
    }

    &::after {
      @include pseudoElementContent($top: 0, $left: 0, $width: 0);
      background-color: $primary;
      visibility: hidden;
      transition: width 0.3s ease-out, 0.3s visibility;
      z-index: 0;
    }

    &:hover {
      &::after {
        width: 5px;
        visibility: visible;
        @include transitionIn(width);
      }
    }

    &:focus {
      &::after {
        width: $max-percentage;
        visibility: visible;
        @include transitionIn(width);
      }
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 1.5rem 0;
  font-family: $font-family-heading;
  -webkit-margin-before: 0px;
  display: inline-block;
  width: $max-percentage;
  vertical-align: middle;
  position: relative;
  font-weight: normal;
  line-height: 1.2em;
  letter-spacing: 1px;
  word-break: break-word;
}

h1,
.h1 {
  font-size: $font-size-heading;

  @include lg {
    font-size: 2.5rem;
  }
  @include sm {
    font-size: 2.1rem;
  }

  &.title {
    font-size: fontSize(3.4);
    margin-bottom: $gap-half-size;

    @include lg {
      font-size: fontSize(3);
      margin-bottom: $gap-size-1x;
    }
  }

  sup {
    vertical-align: super;
  }
}

h2 {
  font-size: fontSize(2.5);
  letter-spacing: -0.63px;

  &.headline {
    text-transform: uppercase;
    text-align: center;

    &:before {
      content: $non-breaking-space;
      display: inline-block;
      width: 32px;
      vertical-align: middle;
      margin: -5px 5px 0 0;
      font-size: inherit;
      line-height: inherit;
      background: center/contain no-repeat $exclamation-mark-icon;
    }
  }
}

h3 {
  font-size: fontSize(2);
  margin-bottom: 1rem;
}

h6 {
  font-size: $font-size;
}

sup {
  vertical-align: top;
  font-size: 0.8em;
}

img {
  max-width: $max-percentage;
  height: auto;
  vertical-align: bottom;

  &.contain {
    object-fit: contain;
  }

  &.placeholder {
    object-fit: cover !important;
  }
}

ul {
  margin-block: 0;
  margin-inline: 0;
  padding-block: 0;
  padding-inline: 0;
  list-style-type: none;
}

dl {
  width: $max-percentage;
  overflow: hidden;
  padding: 0;
  margin: 0;

  dt,
  dd {
    display: inline;
    font-size: fontSize(2);
    letter-spacing: -0.5px;
    line-height: 1.5;
    padding: 0;
    margin: 0;
  }

  dt::before {
    content: "";
    display: block;
  }

  dd {
    font-family: $font-family-heading;
    margin-left: 0.5rem;
  }
}

.textAlign {
  &Center {
    text-align: center;
  }
}

ins {
  background-color: transparent;
  color: $primary;
}
