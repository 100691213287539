footer {
  flex-shrink: 0;
  background: $grey-lighter;
  color: $black;
  margin-top: 2.5rem;
  min-height: $footer-height;
  @include displayFlex($flexDirection: column);

  @include lg {
    margin-top: $gap-size-1x;
  }

  .mainFooterTop {
    @include displayFlex($flexDirection: row, $justifyContent: space-between);
    background-color: $secondary;
    background-color: #ddd;
    background-color: $black;
    background-color: $grey;
    padding: 0.6rem 2.5rem;

    @include lg {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 1.25rem $gap-size-1x;
    }

    @include sm {
      padding: $gap-size-normal;
    }

    .mainFooterLogo {
      width: 30%;
      @include lg {
        width: auto;
      }
      a {
        position: relative;
        svg {
          height: 56px;
          width: 64px;
          fill: $white;
        }
      }
    }

    .newsletter {
      @include displayFlex($alignItems: center, $justifyContent: flex-end);
      gap: 0 0.75rem;
      width: 70%;
      margin-bottom: 0;

      @include lg {
        margin-top: 1.5rem;
        display: grid;
        justify-content: center;
        gap: 1.5rem 2rem;
        grid-template-columns: repeat(2, 1fr);
        width: 100%;
      }

      @include sm {
        @include displayFlex($flexDirection: column, $alignItems: center, $justifyContent: flex-end);
        gap: $gap-size-normal;
      }

      p {
        //color: $white;
        //font-family: $font-family-heading;
        letter-spacing: -0.38;
        margin-bottom: 0;
        @include lg {
          text-align: center;
          grid-column-start: 1;
          grid-column-end: 4;
          grid-row-start: 1;
          grid-row-end: 3;
        }
      }

      input {
        background-color: $white !important;
        color: $black;
        border: none;
        &::placeholder {
          color: $black;
        }

        @include lg {
          grid-column-start: 1;
          grid-column-end: 3;
          grid-row-start: 3;
          grid-row-end: 3;
        }

        @include sm {
          width: $max-percentage;
        }
      }

      button {
        padding: 0.25rem 1.2rem;
        @include lg {
          grid-column-start: 3;
          grid-column-end: 4;
          grid-row-start: 3;
          grid-row-end: 4;
        }
      }
    }

    .nlr {
      padding: $gap-half-size;

      &.nlErr {
        color: $primary;
      }

      &.nlOK {
        color: $white;
      }

      + .nlr {
        padding-top: 0;
      }
    }
  }

  .mainFooterNav {
    @include displayFlex($flexDirection: column);
    max-width: $screen-xl;
    width: 100%;
    padding: 1.25rem 2.5rem;
    font-size: fontSize(1.6);

    @include lg {
      padding: 1.25rem $gap-size-1x;
    }
    @include sm {
      padding: $gap-size-normal;
    }

    .mainFooterNavigation {
      @include displayFlex($justifyContent: space-between);

      @include lg {
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 1.5rem 0;
      }

      @include sm {
        gap: $gap-size-normal 0;
      }

      ul {
        min-width: 170px;
        padding: 0;
        @include lg {
          width: 33%;
        }
        @include sm {
          width: 45%;
        }

        li {
          margin-bottom: 0.6rem;
        }

        a {
          position: relative;
          &::after {
            @include pseudoElementContent($bottom: 0, $left: 0, $width: 0, $height: 1px);
            background-color: $primary;
            visibility: hidden;
          }
          &:hover::after {
            visibility: visible;
            width: 100%;
            transition: width 0.2s ease-in;
          }
        }
      }
    }
  }
  .socials {
    display: grid;
    grid-template-columns: auto auto;
    align-items: start;
    justify-content: start;
    margin-top: 0.2rem;

    a {
      width: 24px;
      height: 24px;
      position: relative;
      &:hover svg {
        animation: scaleIcon 0.4s ease-in-out forwards;
      }
      + a {
        margin-left: 1rem;
      }
    }

    .facebook svg,
    .instagram svg {
      fill: $black;
      transition: 0.2s fill ease-out;
      animation: scaleIconOut 0.4s ease-in-out forwards;

      &:hover {
        transition: 0.2s fill ease-in;
        fill: $primary;
      }
    }
  }
  .version {
    text-align: right;
    font-size: 9px !important;
    bottom: -20px;
    position: relative;
    z-index: 5;
  }
}
