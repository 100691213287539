.user {
  > .form {
    margin-top: $gap-size-3x;
  }

  .logout {
    margin: 0;
    text-align: center;
  }
  .flex {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 2rem;
    @include md {
      display: block;
    }

    .illustration {
      display: block;
      background-image: url("/images/brushes.jpg");
      background-size: cover;
      background-position: 50%;
      align-self: stretch;
      width: 50%;
      min-height: 415px;
      max-height: 800px;
      margin-right: 2rem;

      @include md {
        width: 100%;
        margin-bottom: 2rem;
        min-height: 200px;
      }
    }
    .form {
      max-width: 500px;
      @include md {
        max-width: 100%;
      }
    }
  }
}

#page.login .illustration {
  background-image: url("/images/Malfarben.jpg");
}
#page.forgotten-password .illustration {
  background-image: url("/images/Sonstige.jpg");
}

.user-profile {
  margin-top: $gap-size-1x;
  @include displayFlex($alignItems: flex-start, $justifyContent: flex-start);

  @include lg {
    flex-direction: column;
  }
}

.user-form {
  .user-login {
    margin: auto;
  }

  .user-registration {
    margin: auto;
  }
}

.user-profile-addresses {
  .loader {
    min-height: 600px;

    @include lg {
      min-height: 400px;
    }
  }
}

.user-registration,
.user-login,
.user-pw-change {
  transition: height 0.2s ease-in, min-height 0.2s ease-in;
  min-height: 400px;
  svg {
    width: 25%;
    margin: $gap-size-2x auto $gap-size-1x;
    opacity: 0;
    animation: fadeIn 0.2s ease-in forwards;
  }
}

.user-with-login,
.user-anonymous {
  text-align: center;
}

.form {
  width: 100%;
  max-width: 480px;
  margin: 0 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: $gap-size-1x;
  border: 1px solid $grey;

  &.user-with-login,
  &.user-pw-change {
    max-width: 480px;
    margin: 0;
  }

  form {
    text-align: left;

    h1,
    p {
      text-align: center;
    }

    p {
      margin-bottom: $gap-size-normal;
    }

    + .form-actions {
      margin-top: $gap-size-normal;
    }
  }
}

.info-line {
  display: block;
  text-align: center;
  margin: 0 0 20px;
  color: $grey-dark;
  font-size: 14px;
}

:not(.basket) .actions {
  margin-top: $gap-size-1x;
}

.actions {
  color: $half-black;

  p {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.e,
.ok {
  margin: 5px 0 0 0;
  clear: both;
  color: $primary;
  font-size: 14px;
  position: relative;
}

.ok {
  color: $green;
}
