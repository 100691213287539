.search {
  @include displayFlex($alignItems: center);
  padding: $gap-half-size 0.56rem;
  position: relative;
  border: 1px solid $grey-mid-dark;

  @include lg {
    margin: 0 auto;
  }

  @include md {
    padding: 0;
    border: none;
    margin-left: auto;
    margin-right: $gap-size-normal;

    &:hover {
      .searchIcon svg {
        fill: $primary;
      }
    }

    &.expanded {
      width: 100%;
      background-color: $white;
      z-index: 11;
      border: 1px solid $grey-mid-dark;
      margin-right: 0;
      padding: $gap-half-size $gap-size-normal;
      height: 50px;

      @include lg {
        height: 35px;
      }

      .searchInput {
        width: $max-percentage;
        opacity: 1;
        @include transitionIn(opacity);

        @include sm {
          display: block;
        }
      }

      .reset {
        width: auto;
        opacity: 1;
        @include transitionIn(opacity);
        @include sm {
          display: block;
        }
      }
    }
  }

  .searchInput {
    position: relative;
    opacity: 1;
    background-color: transparent;
    color: $grey-dark;
    border: none;
    width: $max-percentage;
    transition: padding 0.2s ease-in, width 0.2s ease-in;
    height: auto;
    padding: 0 0.6rem;
    outline: none !important;

    @include md {
      width: 0;
      padding: 0;
      opacity: 0;
      transition: none;
      display: none;
    }

    &::placeholder {
      color: $grey-dark;
    }
  }

  .searchIcon svg {
    fill: $grey-dark;
    cursor: pointer;
    width: 16px;
    transition: width $header-transition-duration ease-out;

    @include md {
      width: 30px;
      fill: $black;
    }
  }

  .reset {
    display: inline-block;
    cursor: pointer;
    pointer-events: none;
    opacity: 0;
    @include transitionOut(opacity);

    @include md {
      width: 0;
      opacity: 0;
      display: none;
    }

    &.show {
      opacity: 1;
      pointer-events: all;
      @include transitionIn(opacity);
    }

    svg {
      fill: $grey-dark;
      width: 14px;
    }
  }

  .loader {
    height: auto;
    .loader-ring {
      width: 20px;
      height: 20px;
      position: relative;
      top: 0;
      left: 0;
      transform: none;

      > div {
        width: 20px;
        height: 20px;
        margin: 0;
        border-width: 2px;
      }
    }
  }
}
