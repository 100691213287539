.tabs-navigation {
  overflow: auto;
  padding-bottom: 8px;
  width: 20%;
  padding-right: 1.25rem;

  @include lg {
    width: 100%;
  }

  + div {
    padding-left: 2.5rem;
    width: 80%;

    @include lg {
      padding-left: 0;
      width: 100%;
    }
  }

  ul {
    @include displayFlex($flexDirection: column);
    @include lg {
      flex-direction: row;
    }
  }

  & + .products-list {
    padding-top: 20px;
  }

  li {
    &.active {
      a {
        color: $primary;
      }
    }
    &:hover {
      color: $primary;
    }

    a {
      border-bottom: 1px solid $grey-light;
      padding: $gap-size-normal;
      width: 100%;
      white-space: pre;
    }
  }
}

.tabs-title {
  display: none;
}

.tab {
  overflow: hidden;
  height: 0;
  opacity: 0;
  transition: opacity 0.15s ease-in-out;

  &.visible {
    opacity: 1;
    display: block;
    height: auto;
  }
}
