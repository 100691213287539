.pagination {
  width: 100%;
  margin-top: 2.6rem;

  ul.pageNumbers {
    @include displayFlex($justifyContent: flex-end, $alignItems: center);
    gap: 0 1.3rem;

    li {
      color: $grey-dark;
      cursor: pointer;
      padding: 0.2rem;

      &:hover {
        color: $primary;
      }

      &.active {
        @include displayFlex($justifyContent: center, $alignItems: center);
        width: 40px;
        height: 40px;
        background-color: $grey-dark;
        border-radius: 50%;
        color: $white;

        @include lg {
          width: 35px;
          height: 35px;
        }
        @include sm {
          width: 30px;
          height: 30px;
        }
      }

      button {
        all: initial;
        cursor: pointer;

        &:disabled {
          cursor: not-allowed;
        }

        &.firstPage,
        &.lastPage {
          svg {
            width: 13px;
          }
        }

        &.prev,
        &.next {
          svg {
            width: 7px;
          }
        }

        &:not(:disabled) {
          svg:hover {
            fill: $primary;
          }
        }
      }
    }
  }
}
