@font-face {
  font-family: "Lubalin Graph Std Demi";
  src: url("/fonts/LubalinGraphStd-Demi.woff2") format("woff2"), url("/fonts/LubalinGraphStd-Demi.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lubalin Graph Std Book";
  src: url("/fonts/LubalinGraphStd-Book.woff2") format("woff2"), url("/fonts/LubalinGraphStd-Book.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
