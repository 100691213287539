.productPhotoSlider {
  @include displayFlex;
  gap: 0 2rem;
  width: 100%;

  .swiper {
    width: 100%;

    .swiper-wrapper {
      margin-bottom: 15px;
    }
  }

  .sliderPhoto {
    max-width: 100%;
    height: 90px;
    position: relative;
    border: 1px solid #edecec;
    cursor: pointer;

    &.selected {
      &::after {
        @include pseudoElementContent($width: 100%, $height: 100%, $top: 0, $left: 0);
        border: 2px solid $primary;
      }
    }
  }
}
