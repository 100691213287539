@include lg {
  header .headerTop {
    padding: 1.3rem $gap-size-1x;

    &.searchExpanded {
      &::before {
        height: 5.5rem;
      }
    }

    .headerTopRight {
      .eshopMenu {
        column-gap: 1.5rem;
        margin-right: $gap-size-1x;

        a {
          padding: 0;

          &.eshopMenuBasket {
            .eshopBasketCount {
              top: -22px;
            }
          }

          &.eshopMenuLogin {
            &.active {
              svg {
                fill: $white;
              }
            }
          }
        }

        li {
          position: relative;

          .eshopMenuDropdown {
            right: -140px;
            top: 28px;
          }
        }
      }
    }
  }
}

@include sm {
  header .headerTop {
    padding: $gap-size-normal;

    .headerTopLeft {
      .headerLogo {
        width: 70px;
      }
    }

    .search {
      .searchIcon svg {
        width: 20px;
        transition: width $header-transition-duration ease-out, fill 0.2s ease-in;
      }
    }

    .headerTopRight {
      .eshopMenu {
        a {
          &.eshopMenuBasket {
            svg {
              width: 26px;
            }
          }

          &.eshopMenuLogin {
            svg {
              width: 21px;
            }
          }
        }
      }
    }
  }
}
