.review {
  .reviewHeader {
    @include displayFlex;

    @include lg {
      flex-direction: column;
    }

    h2 {
      flex: 1 1 20%;

      @include lg {
        flex: 1 1 100%;
      }
    }

    .actionPanel {
      @include displayFlex($justifyContent: flex-end, $alignItems: center);
      flex: 2 1 80%;
      column-gap: 3.4rem;

      @include lg {
        flex: 1 1 100%;
        flex-direction: column;
        align-items: flex-start;
        column-gap: 0;
        row-gap: $gap-size-1x;
      }

      ul.actionPanelFilters {
        @include displayFlex;
        column-gap: 1.8rem;
        margin-top: -($line-height * $font-size);

        @include lg {
          margin-top: 0;
          column-gap: $gap-three-quarter-size;
        }

        li {
          cursor: pointer;
          position: relative;
          transition: color 0.2s ease-out;
          white-space: pre;

          &::after {
            @include pseudoElementContent($bottom: 0, $left: 0, $width: 0, $height: 1px);
            z-index: 10;
            background-color: $primary;
            visibility: hidden;
            transition: width 0.2s ease-out;
          }

          &:hover {
            color: $primary;
            transition: color 0.2s ease-in;

            &::after {
              visibility: visible;
              width: 100%;
              transition: width 0.2s ease-in;
            }
          }
        }
      }
    }

    + .error,
    + .success {
      margin-top: $gap-half-size;
    }
  }

  .reviews {
    margin-top: $gap-size-3x;

    @include lg {
      margin-top: $gap-size-1x;
    }

    .reviewsRow {
      max-width: 1000px;
      form {
        margin-bottom: 0;
      }

      .reviewsRowHead {
        @include displayFlex($justifyContent: space-between, $alignItems: flex-end);

        @include lg {
          flex-direction: column;
          align-items: flex-start;
          row-gap: $gap-size-normal;
        }

        .reviewsRowHeadLeft.flex {
          @include displayFlex($justifyContent: space-between, $alignItems: flex-end);
          column-gap: $gap-size-normal;
          @include lg {
            flex-direction: column;
            order: 2;
            align-items: flex-start;
            row-gap: $gap-size-normal;
          }

          input {
            height: auto;
            line-height: 3;
          }
        }

        .reviewsRowHeadRight {
          @include displayFlex($alignItems: center);
          column-gap: $gap-size-1x;

          @include lg {
            order: 1;
            justify-content: space-between;
            width: 100%;
          }
        }
        .productRating {
          padding: 0;
          .rating svg {
            @extend %svg-star-rating-sizes-small;
          }
        }

        .close {
          padding: $gap-half-size $gap-three-quarter-size;
          svg {
            width: 12px;
          }
        }
      }

      .reviewsRowContent {
        margin-top: $gap-size-normal;

        + input[type="submit"] {
          margin-top: $gap-size-1x;
        }
      }

      .reviewsRowRating {
        @include displayFlex($justifyContent: flex-end);
        column-gap: 1.25rem;

        .thumbButton {
          @include displayFlex($alignItems: center);
          column-gap: 0.5rem;
          cursor: pointer;

          svg {
            @extend %svg-thumbs-button-size;
          }
        }
      }

      + .reviewsRow {
        margin-top: $gap-size-3x;
      }

      .reviewsResponse {
        margin-top: 0.5rem;
        padding-left: 9.8rem;

        .reviewsResponseText {
          color: $primary;
        }
      }
    }
  }
}
