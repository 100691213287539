.notFound {
  height: calc(100vh - $header-height - $footer-height - $gap-size-2x);
  align-items: center;
  justify-content: center;
  text-align: center;

  @include lg {
    height: calc(100vh - $header-height-mobile - $footer-height-mobile - $gap-size-2x);
  }
  @include md {
    height: calc(100vh - $header-height-mobile - $footer-height-mobile-md - $gap-size-2x);
  }
}
