input[type="text"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="password"],
input[type="tel"],
textarea,
select {
  background: $white;
  border: 1px solid $grey-mid;
  font-family: $font-family;
  padding: 0 0.9rem;
  color: $grey-dark;
  display: block;
  // height: 2.5rem; // nespecifkovat height, resit pomoci line-height
  line-height: 1.4rem;
  text-align: left;
  transition: all 0.15s ease-out;
  border-radius: 0;
  font-size: 0.875rem;

  &:-webkit-autofill {
    color: $grey-mid;
    background: none !important;
    -webkit-box-shadow: 0 0 0px 1000px $white inset;
    -webkit-text-fill-color: $grey-mid !important;
  }

  &::-webkit-input-placeholder {
    color: $grey-mid;
  }
  &::-moz-placeholder {
    color: $grey-mid;
  }
  &:-ms-input-placeholder {
    color: $grey-mid;
  }
  &:-moz-placeholder {
    color: $grey-mid;
  }

  &:invalid {
    background-color: $white !important;
  }

  &:disabled,
  &:read-only {
    background-color: $white !important;
  }

  /*   &:focus {
    color: $white;
  } */
}

input[type="radio"],
input[type="checkbox"] {
  width: 24px;
  height: 24px;
  border: 2px solid $black;
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &::before {
    @include pseudoElementContent($top: 3px, $left: 3px, $width: 70%, $height: 70%);
    background-color: $primary;
    border-radius: 50%;
    transform: scale(0);
    opacity: 0;
    transition: transform 0.2s ease-out, 0.2s opacity;
  }

  &:checked {
    &::before {
      content: "";
      transform: scale(1);
      opacity: 1;
      transition: transform 0.2s ease-in;
    }
    border-color: $primary;
    accent-color: $primary;
  }

  &:disabled {
    border-color: $grey-light;

    + label {
      color: $grey-light;
    }
  }
}

input[type="checkbox"] {
  border-radius: 0;

  &::before {
    border-radius: 0;
    content: "";
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: $checkmark-icon;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 15px 15px;
  }
}

textarea {
  background: transparent;
  min-height: 100px;
  width: $max-percentage;
  max-width: 1020px;
  padding: 0.9rem;
  resize: vertical;
  line-height: $line-height-big;

  @include lg {
    line-height: $line-height;
  }
}

select {
  appearance: none;
  padding-right: 30px;
  min-width: 70px;
  background: $white $select-icon calc(100% - 10px) 50% / 7px 7px no-repeat;
}

select[multiple],
select[size] {
  background: $white;
  padding-right: 0;
}

select::-ms-expand {
  display: none;
}

label {
  display: block;
  a {
    text-decoration: underline;
  }
}

.formGroup {
  @include displayFlex($justifyContent: space-between);
  column-gap: $gap-size-1x;
  width: 100%;

  @include lg {
    flex-direction: column;
    padding-bottom: $gap-size-1x;
  }
}

.formRow {
  display: block;
  clear: both;
  width: 100%;
  padding: 0 0 1.25rem;
  text-align: left;

  @include lg {
    &:last-child {
      padding: 0;
    }
  }

  &.nbp {
    padding-bottom: 0;
  }

  input,
  select,
  textarea {
    width: 100%;
  }

  input[type="checkbox"] {
    display: inline-flex;
    width: 24px;
    margin-right: $gap-size-normal;
    vertical-align: top;

    + label {
      width: calc(100% - 24px - $gap-size-normal);
    }
  }
}

form {
  input[type="text"],
  input[type="email"],
  input[type="number"],
  input[type="search"],
  input[type="password"],
  input[type="tel"],
  select {
    // height: 2.75rem; // nespecifkovat height, resit pomoci line-height
    line-height: 2rem;
  }
  &.disabled .form {
    pointer-events: none;
    user-select: none;

    input[type="text"],
    input[type="email"],
    input[type="number"],
    input[type="search"],
    input[type="password"],
    input[type="tel"],
    textarea,
    select {
      background-color: $white !important;
    }
  }

  .password {
    position: relative;

    i {
      @include pseudoElementContent($width: 20px, $height: 20px, $top: 50%, $right: 15px);
      padding: $gap-half-size;
      cursor: pointer;
      transform: translateY(-50%);
      z-index: 10;
    }

    .e {
      position: absolute;
    }

    input[type="password"] {
      &::-webkit-contacts-auto-fill-button {
        visibility: hidden;
        display: none !important;
        pointer-events: none;
        position: absolute;
        right: 0;
      }
      + i {
        background: center / contain no-repeat $eye-icon;
      }
    }

    input[type="text"] {
      + i {
        background: center / contain no-repeat $eye-slash-icon;
      }
    }
  }
}

fieldset {
  display: block;
  padding: 1.25rem 0;
  border-top: 1px solid $grey-light;
}

address {
  font-style: normal;

  p {
    margin-bottom: $gap-size-normal;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

legend {
  margin-bottom: $gap-size-normal;
}

.form {
  padding: $gap-size-normal;
  background: $white;
  transition: opacity 0.15s ease-in-out;
  opacity: 1;

  a:not(.button) {
    text-decoration: none;
    &:hover {
      color: $primary;
    }
  }

  .btn {
    width: 100%;
  }

  .formExtend {
    height: 0;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease-out;

    &.extended {
      pointer-events: all;
      transition: opacity 0.2s ease-in;
      height: auto;
      opacity: 1;
    }
  }
}

.form-headline {
  display: block;
  text-align: center;
  color: $half-black;

  @include lg {
    font-size: 25px;
  }
}

input[type="checkbox"] + label {
  display: inline-block;
}

input[type="checkbox"] + label,
input[type="radio"] + label {
  cursor: pointer;
  line-height: 20px;
  position: relative;
}
