.catalogueContent {
  @include displayFlex;
  position: relative;
  padding: 2.5rem 0 0 0;

  @include lg {
    flex-direction: column;
    padding: 0 $gap-size-1x;
  }
  @include sm {
    padding: 0 $gap-size-normal;
  }

  .totalProducts {
    position: absolute;
    top: $gap-half-size;
    right: $gap-size-2x;
  }

  .catalogue {
    width: 80%;
    padding: 0 0 0 2.5rem;

    @include xlx {
      padding: 0 2.5rem 0 2.5rem;
    }

    @include lg {
      width: 100%;
      padding: $gap-size-1x 0;
    }
  }

  .catalogue {
    .structuredResults {
      .catalogueList {
        margin-bottom: 3.5rem;
      }
    }
    .catalogueList {
      display: grid;
      grid-template-rows: repeat(auto-fit, 1fr);
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      gap: 3.4rem 2.6rem;

      @include xl {
        grid-template-columns: repeat(3, 1fr);
        gap: 1.5rem;
      }
      @include md {
        grid-template-columns: repeat(2, 1fr);
      }
      @include sm {
        grid-template-columns: repeat(1, 1fr);
      }

      .catalogueListItem {
        width: auto;
      }

      /*   .catalogueListBanner {
        grid-column: 1 / -1;
      } */
    }

    .empty {
      @include displayFlex($alignItems: center, $justifyContent: flex-end);
      width: 100%;
    }
  }
}

.detail {
  width: 100%;

  .productMain {
    @include displayFlex($justifyContent: space-between);

    @include lg {
      flex-direction: column;
    }

    .leftCol {
      width: 40%;
      @include lg {
        width: $max-percentage;
      }
    }

    .rightCol {
      width: 58%;
      @include lg {
        width: $max-percentage;
      }

      ul li {
        list-style-type: disc;
        margin: 0 0 0 1rem;
      }
    }

    .titleWithFavorites {
      @include displayFlex($alignItems: flex-start, $justifyContent: space-between);
      overflow: visible;

      .title {
        width: 80%;
        margin-bottom: 0;
      }
    }

    .info {
      p {
        margin-bottom: $gap-size-normal !important;
        span {
          color: $primary !important;
        }

        &:last-child {
          margin-bottom: 0 !important;
        }
      }

      @include lg {
        margin-top: $gap-size-1x;
      }
    }
  }

  .description {
    .bulletPoints {
      margin-top: $gap-size-1x;

      ul {
        li + li {
          margin-top: $gap-half-size;
        }
      }
    }

    p {
      white-space: pre-line;
      max-width: $text-max-width;

      @include lg {
        max-width: 750px;
      }
      @include md {
        max-width: 85%;
      }
    }
  }

  .similarProducts {
    margin-top: 5.3rem;
    @include lg {
      margin-top: $gap-size-2x;
    }

    .line {
      .catalogueListItem {
        width: auto;
      }
    }
  }

  .catalogueListBanner {
    margin-top: 3.3rem;
    padding: $gap-size-2x;

    @include lg {
      height: auto;
      padding: $gap-size-1x;
    }
  }
}
