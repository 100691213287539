#map-search {
  position: absolute;
  bottom: $gap-size-normal;
  left: $gap-size-normal;
  min-width: 280px;
  z-index: 9;
  background-color: $secondary;
  padding: $gap-size-small;
  opacity: 0.2;

  &:hover,
  &:focus-within {
    opacity: 1;
    @include transitionIn(opacity);

    @include lg {
      min-width: 250px;
      @include transitionIn(min-width);
    }
  }

  @include lg {
    min-width: 100px;
    @include transitionIn(min-width);
  }

  fieldset {
    display: flex;
    border-top: none;
    padding: 0;

    input {
      height: 2rem;
      width: 100%;
      font-size: fontSize(1.2);
    }

    button {
      padding: 0.5rem 1rem;
      height: 2rem;
      svg {
        fill: $white;
        width: 15px;
      }
    }
  }
}

#map {
  height: 50vh;
  background-color: $grey-light;
  position: relative;
  z-index: 1;
}

.marker {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 2rem;
  background: $primary;
  display: inline-block;
  border-bottom-right-radius: 0;
  position: relative;
  transform: rotate(45deg);
  border: 1px solid #881100;

  &::before {
    content: "";
    background: $white;
    width: 50%;
    height: 50%;
    border-radius: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0.1rem 0.1rem 0.2rem 0.1rem rgba(0, 0, 0, 0.1);
  }

  &::after {
    content: "";
    background: rgba(128, 128, 128, 0.2);
    width: 75%;
    height: 75%;
    border-radius: 100%;
    position: absolute;
    top: 100%;
    left: 100%;
    transform: translate(-50%, -50%) rotate(45deg) scaleX(0.5);
  }

  &:hover {
    z-index: 90;
  }

  &.expanded {
    z-index: 99;
    + .info-window {
      opacity: 1;
    }
  }
}

.info-window {
  position: relative;
  bottom: 0px;
  left: 10px;
  min-width: 260px;
  z-index: 100;
  box-shadow: 0px 0px 5px 0px rgb(85 85 85 / 50%);
  transform: translate(-50%, calc(-100% - 18px));
  opacity: 0;
  transition: opacity 0.15s ease-in-out;
  background: $white;
  padding: 0px 20px;

  .close {
    position: absolute;
    z-index: 9;
    top: 2px;
    right: 2px;
    width: 18px;
    height: 18px;
    background: $black $close-icon no-repeat center center;
    background-size: 50%;

    &:hover {
      background-color: $primary;
    }
  }

  .info {
    padding: 20px 0px;
    z-index: 2;
    position: relative;
    background-color: $white;
  }

  &:after {
    content: "";
    width: 20px;
    height: 20px;
    position: absolute;
    z-index: 1;
    left: 50%;
    bottom: -10px;
    transform: translateX(-50%) rotate(45deg);
    background: $white;
    box-shadow: 0px 0px 5px 0px rgb(85 85 85 / 50%);
  }
  strong,
  address {
    display: block;
    margin: 0px 0px 20px;
    font-size: 16px;
  }

  strong {
    margin: 0px 0px 10px;
  }

  address {
    font-size: 14px;
    white-space: pre-wrap;
  }
}

.cluster-marker {
  color: $white;
  background: $primary;
  border-radius: 50%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.crime-marker {
  background: none;
  border: none;
}

.crime-marker img {
  width: 25px;
}
