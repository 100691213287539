// font
$font-family: "Lubalin Graph Std Book", Arial, sans-serif;
$font-family-heading: "Lubalin Graph Std Demi", Arial, sans-serif;
$font-size: 1rem;
$font-size-heading: 3rem;
$line-height: 1.5;
$line-height-big: 1.6;

$header-height: 13.43rem;
$header-height-shrink: 8rem;
$header-height-mobile: 7.1rem;
$header-height-mobile-shrink: 5.8rem;
$header-height-mobile-shrink: 6.3rem;
$header-height-mobile-shrink-sm: 4.9rem;
$header-top-height: 5.6rem;
$header-top-height-shrink: 3rem;
$header-logo-height: 6rem;
$header-nav-height: 4.5rem;
$header-nav-height-shrink: 3.25rem;
$header-nav-width-mobile: 15.5rem;
$header-nav-padding-mobile: $header-nav-height 0 0 2rem;
$header-eshop-menu-dropdown-width: 21.8rem;
$footer-height-mobile-md: 34.5rem;
$footer-height-mobile: 27.25rem;
$footer-height: 15rem;

$gap-size-small: 0.2rem;
$gap-half-size: 0.5rem;
$gap-three-quarter-size: 0.75rem;
$gap-size-normal: 1rem;
$gap-size-1x: 1.5rem;
$gap-size-2x: 2.5rem;
$gap-size-3x: 3rem;
$gap-size-4x: 4.3rem;

$header-transition-duration: 0.2s;

$section-height: auto; //18.25rem;

$catalogue-product-image-height: 25rem;

$text-max-width: 1000px;

$max-percentage: 100%;
$half-percentage: 50%;

// colors
$primary: #da1d2a;
$primary-light: rgba(218, 29, 42, 0.05);
$white: #fff;
$half-white: rgba(255, 255, 255, 0.5);
$black: #000;
$black-logo: #231f20;
$half-black: rgba(0, 0, 0, 0.5);
$half-black-light: rgba(0, 0, 0, 0.43);
$secondary: #ffdd18;
$grey: #ececec;
$grey-light: #edecec;
$grey-lighter: #f9f9f9;
$grey-dark: #636363;
$grey-mid: #827f7f;
$grey-mid-dark: #979797;
$green: #1a842c;
$section-background: #aaa;
$toastSuccessColor: #21b83a;
$toastErrorColor: #da1d2a;

$shadow: 0 2px 7px 0 $half-black;
$shadow-bottom: 1px 1px 2px 0px rgba(95, 31, 31, 0.22);

// urls

// icons
$checkmark-icon: url("/svg/checkmark.svg");
$play-icon: url("/svg/playCircle.svg");
$pause-icon: url("/svg/pauseCircle.svg");
$close-icon: url("/svg/xmark.svg");
$exclamation-mark-icon: url("/svg/exclamationmark.svg");
$exclamation-mark-warn-icon: url("/svg/exclamationmark-warn.svg");
$truck-icon: url("/svg/truck.svg");
$plus-icon: url("/svg/plusCircle.svg");
$minus-icon: url("/svg/minusCircle.svg");
$trash-icon: url("/svg/trashCircle.svg");
$eye-icon: url("/svg/eye.svg");
$eye-slash-icon: url("/svg/eyeSlash.svg");
$select-icon: url("/svg/select.svg");

// images
// categories
$MalfarbenBg: url("/images/Malfarben.jpg");
$MalenBg: url("/images/Malen.jpg");
$ZeichnenBg: url("/images/Zeichnen.jpg");
$SchreibenBg: url("/images/Schreiben.jpg");
$SonstigeBg: url("/images/Sonstige.jpg");
// catalogue
$catalogueListBanner: url("/images/Banner1.jpg");
// home
$image1: url("/images/LP-02.jpg");
$image2: url("/images/LP-03.jpg");
$image3: url("/images/LP-04.jpg");

// static pages
$sectionFullImage: url("/images/About02.jpg");
$sectionRightImage: url("/images/About03.jpg");

// entity
$non-breaking-space: "\a0";
$slash: "/";
