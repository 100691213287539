.loader {
  height: 200px;
  width: 100%;
  position: relative;

  .loader-ring {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 64px;
    height: 64px;

    div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 51px;
      height: 51px;
      margin: 6px;
      border: 6px solid $primary;
      border-radius: 50%;
      animation: loadingSpinnerRight 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: $primary transparent transparent transparent;

      &:nth-child(1) {
        animation-delay: -0.45s;
      }
      &:nth-child(2) {
        animation-delay: -0.15s;
      }
      &:nth-child(3) {
        animation-delay: -0.15s;
      }
    }
  }
}
