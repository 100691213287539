.sum-total,
.sum-discount,
.sum-items {
  width: $max-percentage;
  background: rgba($secondary, 0.4);
  color: $black;
  @include displayFlex($alignItems: center, $justifyContent: space-between);
  font-size: fontSize(1.75);
  margin-top: $gap-size-1x;
  padding: $gap-size-normal;

  @include lg {
    margin-top: 0;
    padding: $gap-size-1x $gap-size-normal;
    display: grid;
    align-items: start;

    &.onlyPrice {
      display: flex;
    }

    button {
      grid-row: 1 / 3;
      grid-column: 2 / 3;
    }
  }

  @include sm {
    padding-top: 15px;
    padding-bottom: 15px;

    strong {
      display: block;
    }
  }

  + .sum-total,
  + .sum-discount {
    margin-top: 0;
  }
}

.sum-total,
.sum-discount {
  background: $secondary;
  color: $black;
}
