.cookieConsent {
  position: fixed;
  z-index: 100;
  bottom: $gap-size-normal;
  right: 2rem;
  background-color: $grey-lighter;
  max-width: 550px;
  padding: 1.2rem;
  box-shadow: $shadow;
  opacity: 0;
  transition: opacity 0.3s ease-in;
  font-size: 0.8rem !important;

  @include lg {
    right: $gap-size-1x;
  }

  @include sm {
    right: 0;
    margin: 0 $gap-size-normal;
  }

  h4 {
    font-size: 1.3rem !important;
    margin-bottom: 1rem;
  }
  p {
    font-size: 0.8rem !important;
    margin-bottom: 1rem;
  }

  &.visible {
    opacity: 1;
    transition: 0.1s opacity 0.3s ease-in;
  }
  article {
    margin-bottom: 1.2rem;
  }
  button {
    font-size: 0.9rem !important;
    + button {
      margin-left: 1rem;
    }
  }
}
