.tooltipWrapper {
  position: relative;
  top: 0;
  left: 0;
  display: inline-flex;

  &:hover {
    .tooltip {
      z-index: 100;
      opacity: 1;
    }
  }

  .tooltip {
    position: absolute;
    top: -55px;
    left: 0;
    background-color: $white;
    left: 50%;
    transform: translateX(-50%);
    min-width: 150px;
    min-height: 30px;
    z-index: 100;
    box-shadow: 0px 0px 5px 0px rgb(85 85 85 / 50%);
    opacity: 0;
    transition: opacity 0.15s ease-in-out;
    padding: 10px 15px;
    display: flex;

    .info {
      width: 100%;
      background-color: $white;
      min-height: 100%;
      @include displayFlex($alignItems: center, $justifyContent: center);
      font-size: fontSize(1.5);
    }

    &:before {
      content: "";
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $white;
      z-index: -1;
    }

    &:after {
      content: "";
      width: 20px;
      height: 20px;
      position: absolute;
      z-index: -2;
      left: 50%;
      bottom: -10px;
      transform: translateX(-50%) rotate(45deg);
      background: $white;
      box-shadow: 0px 0px 5px 0px rgb(85 85 85 / 50%);
    }
  }
}
