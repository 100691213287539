.productPhoto {
  border: 1px solid #edecec;
  height: $catalogue-product-image-height;
  @include displayFlex($justifyContent: center, $alignItems: center, $flexDirection: column);
  position: relative;
  overflow: hidden;
  margin-bottom: 1rem;
  cursor: zoom-in;

  &:hover img {
    opacity: 0;
  }
}
