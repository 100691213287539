@include lg {
  header.shrink {
    border-bottom: 0.2rem solid $primary;
    .headerTop {
      padding: 1.3rem $gap-size-1x 1rem;

      .search {
        .searchIcon svg {
          width: 21px;
        }
      }

      .headerTopRight {
        .eshopMenu {
          a.eshopMenuBasket .eshopBasketCount {
            top: -15px;
          }
        }
      }
    }

    .headerNavWrap .headerNav {
      height: 100vh;
    }
  }
}

@include sm {
  header.shrink {
    .headerTop {
      padding: $gap-size-normal $gap-size-normal 0.5rem;

      .headerTopLeft .headerLogo {
        width: 50px;
      }

      .search {
        transition: none;
        .searchIcon svg {
          transition: fill 0.2s ease-in;
          width: 20px;
        }
      }
    }
  }
}
