// extend

%svg-star-rating-sizes-small {
  width: 20px;
  height: 20px;
}

%svg-star-rating-sizes-normal {
  width: 24px;
  height: 24px;
}

%svg-fill-transition {
  transition: fill 0.2s ease-in;
}

%svg-favorite-size {
  width: 25px;
}

%svg-thumbs-button-size {
  width: 24px;
}

%number-counter {
  @extend %rounded;
  background: $black;
  color: $white;
  content: counter(item, decimal);
  display: block;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 28px;
  transition: all 0.15s ease-in-out;
  font-size: 16px;
  font-weight: 400;
}

%rounded {
  border-radius: 50%;
  overflow: hidden;
}

%steps {
  list-style-type: none;
  counter-reset: item;

  li {
    list-style-type: none;
    counter-increment: item;

    &:before {
      @extend %number-counter;
    }
  }
}
