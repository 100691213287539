header.shrink {
  .headerTop {
    padding: 0.5rem 2.56rem;
    @include transitionIn(padding);

    .headerTopLeft {
      .headerLogo {
        width: 60px;
        transition: width $header-transition-duration ease-out;
      }
    }

    .search {
      padding: 0.3rem 0.5rem;
      //height: 35px;
      transition: all $header-transition-duration ease-out;
      .searchInput {
        font-size: 0.8rem;
      }
      .searchIcon svg {
        width: 12px;
      }
    }

    .headerTopRight {
      min-width: 130px;
      .eshopMenu {
        column-gap: 1.5rem;
        a {
          &.eshopMenuBasket {
            svg {
              transition: width $header-transition-duration ease-out, fill 0.2s ease-out;
              width: 26px;
            }

            .eshopBasketCount {
              width: 20px;
              height: 20px;
              font-size: fontSize(1.4);
              top: -6px;
              transition: width $header-transition-duration ease-out,
                height $header-transition-duration ease-out, top 0.3s ease-out;
            }
          }

          &.eshopMenuLogin {
            svg {
              transition: width $header-transition-duration ease-out, fill 0.2s ease-out;
              width: 21px;
            }
          }
        }
      }
    }
  }

  .headerNavWrap .headerNav {
    height: $header-nav-height-shrink;

    @include min-lg {
      &.open {
        height: 100vh;
      }

      &:not(.open) {
        nav .headerNavCenter ul.mainMenu li a {
          padding: $gap-half-size 0;
          // transition: padding $header-transition-duration ease-out; -- tohle rozhodi timing u animace zmeny barvy textu
        }
      }

      .headerNavLeft {
        @include transitionIn(width);
      }
    }
  }
}
