main .banner {
  margin-top: 1.8rem;
  @include lg {
    margin-top: 0rem;
  }
  &:not(:first-child) {
    margin-top: 2.5rem;
  }
}

.banner {
  background-color: $half-black;
  color: $white;
  padding: 0 5.3rem 0 3.1rem;
  position: relative;
  height: 350px;
  position: relative;

  @include xlx {
    padding: 0 $gap-size-2x;
  }
  @include lg {
    padding: 0 $gap-size-1x;
    height: 300px;
  }
  @include sm {
    height: 280px;
    padding: 0 $gap-size-normal;
  }

  &:hover {
    cursor: $play-icon 0 0, auto;

    .bannerInner p {
      animation: fadeOut 0.4s ease-out forwards;
    }

    &:not(.disabled) {
      h1::before {
        opacity: 1;
        @include transitionIn(opacity);
      }
    }
  }

  &::after {
    @include pseudoElementContent($top: 0, $left: 0);
    background: $half-black-light center / cover no-repeat fixed;
    background-blend-mode: multiply;
    background-image: $MalfarbenBg;
    opacity: 1;
    transition: opacity 0.3s ease-out;
    z-index: 0;
  }

  &:hover::after {
    background-color: $white;
    opacity: 0;
    transition: opacity 0.3s ease-in;
  }

  &.disabled {
    &:hover {
      cursor: auto;
    }
    &::after {
      opacity: 1 !important;
      background-color: $half-black-light;
    }
  }

  video {
    &::-webkit-media-controls,
    ::-webkit-media-controls-panel {
      display: none;
    }
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &.Malen {
    position: relative;
    &::after {
      background-image: $MalenBg;
      transform: scaleX(-1);
    }
  }
  &.Zeichnen {
    &::after {
      transform: scaleX(-1);
      background-image: $ZeichnenBg;
    }
  }
  &.Schreiben {
    &:hover {
      cursor: auto;
      &::after {
        opacity: 1 !important;
        background-color: $half-black-light;
      }
    }
    &::after {
      background-image: $SchreibenBg;
    }
  }
  &.Sonstige {
    &::after {
      background-image: $SonstigeBg;
    }
  }

  &:not(.disabled) {
    h1::before {
      @include pseudoElementContent($top: -40px, $left: 0, $width: 30px, $height: 30px);
      @include transitionOut(opacity);
      z-index: 1000;
      background-image: $play-icon;
      background-size: contain;
      opacity: 0.7;
      @include sm {
        @include pseudoElementContent($top: -50px, $left: 50%, $width: 35px, $height: 35px);
        transform: translate(-50%, 0);
      }
    }
  }

  .bannerInner {
    @include displayFlex($justifyContent: flex-start, $alignItems: center);
    height: 100%;
    position: inherit;
    z-index: 5;

    @include sm {
      flex-direction: column;
      justify-content: center;
    }

    p {
      max-width: 900px;
      margin-left: 3.9rem;
      animation: fadeIn 0.4s ease-out forwards;

      @include sm {
        margin-left: 0;
        margin-top: $gap-size-normal;
      }
    }

    p,
    h1 {
      margin-bottom: 0;
      width: auto;
    }
  }
}
