.user-profile-orders {
  .info-line {
    margin-top: $gap-size-1x;
    margin-bottom: 0;
    text-align: left;
  }

  .orderDetail {
    .table {
      margin-bottom: $gap-size-1x;
      table {
        @include lg {
          &:after {
            clear: both;
          }
          thead {
            width: $half-percentage;
            float: left;
          }
          tbody {
            width: $half-percentage;
            float: right;
          }
        }

        tr {
          @include lg {
            display: block;
            width: $max-percentage;
          }

          th,
          td {
            @include lg {
              padding: 0.5rem 1rem 0.5rem 0.5rem;
              display: block;
              width: $max-percentage;

              &:last-child {
                text-align: left;
              }
            }
          }
        }
      }
    }
  }
}
