.tabs {
  width: 100%;
  margin-top: 3.5rem;
  position: relative;
  z-index: 1;

  @include lg {
    margin-top: $gap-size-2x;
  }

  .tabWrapper {
    border-bottom: 1px solid $grey-dark;
    margin-bottom: $gap-size-1x;

    @include lg {
      margin-bottom: $gap-size-1x;
    }

    .tabInnerWrapper {
      max-height: 40px;
      min-height: 40px;

      [aria-label="tabs"] {
        position: relative;
        z-index: 2;
      }

      .MuiTabs-indicator {
        height: 100%;
        background-color: $grey-dark;
        z-index: 1;
      }

      .MuiTabs-scrollButtons.Mui-disabled {
        opacity: 0.3;
      }

      button {
        color: $grey-dark;
        min-height: 40px;
        border: 0;
        transition: none;

        &:hover {
          background-color: $grey;
        }

        &.Mui-selected {
          color: $white;

          &:hover {
            background-color: $grey-mid;
          }
        }
      }
    }
  }
}
