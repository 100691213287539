.categories {
  overflow: hidden;
  position: relative;
  z-index: 2;
  background-color: $white;

  .categoriesHeadlineWrapper {
    @include displayFlex($alignItems: center);
    margin-bottom: $gap-size-normal;
    z-index: 10;
    background-color: $white;
    overflow: hidden;
    padding-top: 2px;
    position: relative;
    top: -2px;

    @include lg {
      margin-bottom: $gap-half-size;
    }

    h2 {
      margin-bottom: 0;
      @include lg {
        font-size: fontSize(2);
      }
      @include sm {
        font-size: fontSize(1.9);
      }
    }

    .categoriesVisibilityController {
      @include displayFlex;
      cursor: pointer;

      img {
        @include lg {
          width: 17px;
        }
        @include sm {
          width: 15px;
        }
      }
    }
  }

  &.visible {
    .categoriesHeadlineWrapper {
      .categoriesVisibilityController img {
        transform: rotate(0deg);
        transition: transform 0.2s ease-out;
      }
    }
    .categoriesWrapper {
      transition: transform 0.25s ease-out, opacity 0.25s ease-out;
      max-height: 100%;
      opacity: 1;
    }
  }

  &.hidden {
    .categoriesWrapper {
      max-height: 0;
      transform: translateY(-1000px);
      transition: transform 0.25s ease-out, opacity 0.25s ease-out;
      opacity: 0;
      padding-bottom: 0;

      @include sm {
        opacity: 0;
        height: 0;
        width: 0;
      }

      * {
        pointer-events: none !important;
        visibility: hidden;
        transition: 0.3s visibility;
      }
    }
    img {
      transform: rotate(180deg);
      transition: transform 0.2s ease-out;
    }
  }

  .categoriesBtns {
    @include displayFlex($flexDirection: row, $alignItems: flex-start);
    flex-direction: column;
    gap: 0.5rem;
    position: relative;
    z-index: -1;
    background-color: $white;
    padding-bottom: 2rem;
    width: $max-percentage;

    .categoriesBtns {
      padding-bottom: 0;
    }

    @include lg {
      flex-direction: row;
      flex-wrap: wrap;
      padding-bottom: $gap-half-size;
      gap: 0.25rem 0.5rem;
    }

    li {
      @include displayFlex($alignItems: flex-start, $justifyContent: space-between);
      width: $max-percentage;

      .subLink {
        word-break: break-word;
        width: 80%;
        @include lg {
          font-size: fontSize(1.7);
        }

        &.active {
          color: $primary;
        }
      }

      .categoryOpener {
        position: relative;
        width: 17px;
        height: 17px;
        display: inline-block;
        cursor: pointer;
        margin-top: 4px;

        svg {
          width: 17px;
          height: 17px;
          fill: $black;
        }

        &:hover {
          svg {
            fill: $primary;
          }
        }
      }
    }
  }
}
