// Homepage products
.products {
  @for $i from 1 through 4 {
    .catalogueListItem:nth-child(#{$i}) {
      opacity: 0;
      &[data-inviewport="true"] {
        -webkit-animation: fadeIn
          $i *
          150ms
          ease-in
          $i *
          100ms
          forwards; /* Safari, Chrome and Opera > 12.1 */
        -moz-animation: fadeIn $i * 150ms ease-in $i * 100ms forwards; /* Firefox < 16 */
        -ms-animation: fadeIn $i * 150ms ease-in $i * 100ms forwards; /* Internet Explorer */
        -o-animation: fadeIn $i * 150ms ease-in $i * 100ms forwards; /* Opera < 12.1 */
        animation: fadeIn $i * 150ms ease-in $i * 100ms forwards;
      }
    }
  }
}

// Category | Focus page products
.textWithProducts .productRow {
  @for $i from 1 through 3 {
    .catalogueListItem:nth-child(#{$i}) {
      opacity: 0;
      &[data-inviewport="true"] {
        -webkit-animation: fadeIn
          $i *
          150ms
          ease-in
          $i *
          100ms
          forwards; /* Safari, Chrome and Opera > 12.1 */
        -moz-animation: fadeIn $i * 150ms ease-in $i * 100ms forwards; /* Firefox < 16 */
        -ms-animation: fadeIn $i * 150ms ease-in $i * 100ms forwards; /* Internet Explorer */
        -o-animation: fadeIn $i * 150ms ease-in $i * 100ms forwards; /* Opera < 12.1 */
        animation: fadeIn $i * 150ms ease-in $i * 100ms forwards;
      }
    }
  }
}

.catalogueList {
  .catalogueListItem,
  .catalogueListBanner {
    opacity: 0;
  }

  @media screen and (min-width: 1142px) {
    div:nth-child(1n):not(.catalogueListBanner) {
      &[data-inviewport="true"] {
        -webkit-animation: fadeIn 150ms ease-in 100ms forwards; /* Safari, Chrome and Opera > 12.1 */
        -moz-animation: fadeIn 150ms ease-in 100ms forwards; /* Firefox < 16 */
        -ms-animation: fadeIn 150ms ease-in 100ms forwards; /* Internet Explorer */
        -o-animation: fadeIn 150ms ease-in 100ms forwards; /* Opera < 12.1 */
        animation: fadeIn 150ms ease-in 100ms forwards;
      }
    }
    .catalogueListBanner {
      &[data-inviewport="true"] {
        -webkit-animation: fadeIn 150ms ease-in 100ms forwards; /* Safari, Chrome and Opera > 12.1 */
        -moz-animation: fadeIn 150ms ease-in 100ms forwards; /* Firefox < 16 */
        -ms-animation: fadeIn 150ms ease-in 100ms forwards; /* Internet Explorer */
        -o-animation: fadeIn 150ms ease-in 100ms forwards; /* Opera < 12.1 */
        animation: fadeIn 150ms ease-in 100ms forwards;
      }
    }
    div:nth-child(3n + 2) {
      &[data-inviewport="true"] {
        -webkit-animation: fadeIn 2 * 150ms ease-in 2 * 100ms forwards; /* Safari, Chrome and Opera > 12.1 */
        -moz-animation: fadeIn 2 * 150ms ease-in 2 * 100ms forwards; /* Firefox < 16 */
        -ms-animation: fadeIn 2 * 150ms ease-in 2 * 100ms forwards; /* Internet Explorer */
        -o-animation: fadeIn 2 * 150ms ease-in 2 * 100ms forwards; /* Opera < 12.1 */
        animation: fadeIn 2 * 150ms ease-in 2 * 100ms forwards;
      }
    }
    div:nth-child(3n + 3) {
      &[data-inviewport="true"] {
        -webkit-animation: fadeIn 3 * 150ms ease-in 3 * 100ms forwards; /* Safari, Chrome and Opera > 12.1 */
        -moz-animation: fadeIn 3 * 150ms ease-in 3 * 100ms forwards; /* Firefox < 16 */
        -ms-animation: fadeIn 3 * 150ms ease-in 3 * 100ms forwards; /* Internet Explorer */
        -o-animation: fadeIn 3 * 150ms ease-in 3 * 100ms forwards; /* Opera < 12.1 */
        animation: fadeIn 3 * 150ms ease-in 3 * 100ms forwards;
      }
    }
  }

  @media screen and (max-width: 1141px) {
    div:nth-child(1n) {
      &[data-inviewport="true"] {
        -webkit-animation: fadeIn 150ms ease-in 100ms forwards; /* Safari, Chrome and Opera > 12.1 */
        -moz-animation: fadeIn 150ms ease-in 100ms forwards; /* Firefox < 16 */
        -ms-animation: fadeIn 150ms ease-in 100ms forwards; /* Internet Explorer */
        -o-animation: fadeIn 150ms ease-in 100ms forwards; /* Opera < 12.1 */
        animation: fadeIn 150ms ease-in 100ms forwards;
      }
    }
    div:nth-child(2n + 2) {
      &[data-inviewport="true"] {
        -webkit-animation: fadeIn 2 * 150ms ease-in 2 * 100ms forwards; /* Safari, Chrome and Opera > 12.1 */
        -moz-animation: fadeIn 2 * 150ms ease-in 2 * 100ms forwards; /* Firefox < 16 */
        -ms-animation: fadeIn 2 * 150ms ease-in 2 * 100ms forwards; /* Internet Explorer */
        -o-animation: fadeIn 2 * 150ms ease-in 2 * 100ms forwards; /* Opera < 12.1 */
        animation: fadeIn 2 * 150ms ease-in 2 * 100ms forwards;
      }
    }
  }
}

.catalogueListItem {
  @include displayFlex($flexDirection: column);
  width: 22%;
  height: auto;
  position: relative;
  z-index: 1;
  top: 0;
  left: 0;

  @include xl {
    width: 33%;
  }
  @include lg {
    width: 48%;
  }

  &:hover {
    img {
      transition: transform 0.2s ease-in !important;
      transform: scale(1.05);
    }
  }

  .catalogueProduct {
    z-index: 2;
    position: relative;
    padding: 1px;

    > a {
      width: 100%;
      transition: none !important;
    }
  }

  .listItemName {
    margin: 0.6rem $gap-size-normal;
    text-align: left;
    height: ($line-height * 3) - 0.1rem;
    overflow: hidden;

    @include xlx {
      height: ($line-height * 2) + rem;
      margin: 0.6rem $gap-half-size;
    }
    @include lg {
      height: ($line-height * 2) + rem;
      margin: 0.25rem $gap-half-size;
    }

    h2 {
      letter-spacing: 0.27px;
      margin-bottom: 0;
      font-family: $font-family;
      font-size: 0.875rem;
      line-height: 1.4rem;
    }
  }

  &[data-inviewport="true"] {
    .listItemImageWrapper {
      &::after {
        animation: 1.5s ease-out rollBadgeAfter;
      }
    }
  }

  .listItemImageWrapper {
    position: relative;

    &.showBadge {
      &::before {
        content: "";
      }
      &::after {
        content: attr(data-label);
      }
    }

    &::before {
      position: absolute;
      top: 1.95rem;
      right: -0.5rem;
      background: $primary;
      height: 28px;
      width: 28px;
      transform: rotate(45deg);
      z-index: 0;
    }

    &::after {
      position: absolute;
      font-size: fontSize(1.5);
      top: 11px;
      z-index: 10;
      right: -14px;
      padding: 0.5rem;
      width: 5rem;
      background: $primary;
      color: $white;
      text-align: center;
      box-shadow: 4px 4px 15px rgba(26, 35, 126, 0.2);
    }
  }

  .listItemImage {
    margin: 1rem 0;
    position: relative;
    width: 100%;
    max-height: 180px;
    height: 10vw;
    overflow: hidden;
    background-color: $white;

    @include lg {
      height: 200px;
    }
    @include sm {
      height: 155px;
    }

    img {
      transition: transform 0.6s ease-out;
      margin: 0 auto;
      display: block;
    }
  }

  .listItemBottom {
    @include displayFlex($flexDirection: column);
    position: relative;
    z-index: 2;
    background-color: $white;
    margin-top: 0.4rem;

    .listItemPrice {
      @include displayFlex($alignItems: center, $justifyContent: flex-end);
      padding: 0 0.8rem;

      p {
        font-size: fontSize(1.9);
        margin-bottom: 0;
      }
    }

    .listItemControls {
      @include displayFlex($justifyContent: space-between);
      height: 45px;
      margin-top: 0.4rem;

      @include lg {
        width: $max-percentage;
        height: 40px;
      }
      @include sm {
        height: 38px;
      }

      &.outOfStock {
        opacity: 0.7;
      }

      .listItemAmount {
        @include displayFlex($justifyContent: center, $alignItems: center);
        width: calc(50% + 1rem);
        background-color: $grey-light;
        min-height: 100%;
        color: $black;
        vertical-align: top;
        position: relative;
        left: 0;

        @include xl {
          width: 50%;
        }

        .listItemAmountInner {
          @include displayFlex($justifyContent: center, $alignItems: center);
          width: 100%;
          gap: 0 1.4rem;
          margin-right: 0.9rem;

          @include xl {
            gap: 0 0.9rem;
          }

          svg {
            width: 20px;

            @include xl {
              width: 18px;
            }
          }
        }

        .amount {
          font-size: fontSize(1.9);
          line-height: 1;
          position: relative;
          &.animated {
            animation: pulseInWithOpacity 0.25s ease-out;
          }
        }

        button {
          background-color: transparent;
          color: $black;
          padding: 0;
          line-height: 1;
          vertical-align: middle;

          &:disabled {
            cursor: not-allowed;
            svg {
              fill: $grey-dark;
            }
          }

          &:hover:not(:disabled) {
            svg {
              fill: $primary;
            }
          }
        }
      }

      .listItemAddToCart {
        @include displayFlex($justifyContent: center, $alignItems: center);
        width: calc(50% - 1rem);
        background-color: $grey-dark;
        color: $white;
        font-size: fontSize(1.4);
        min-height: 100%;
        letter-spacing: 0.18px;
        position: relative;
        cursor: pointer;
        padding: 0 0.5rem;
        white-space: nowrap;
        z-index: 3;
        @include transitionOut(background-color);

        @include xl {
          width: 50%;
        }

        &.disabled {
          cursor: not-allowed;
        }

        &:hover:not(.disabled) {
          background-color: $primary;
          transition: none;
          &::after {
            transition: none;
            background-color: $primary;
          }
        }

        &::after {
          @include pseudoElementContent($top: 0, $left: 0, $width: 40px);
          @include transitionOut(background-color);
          transform: skew(-24deg, 0);
          transform-origin: top left;
          background-color: $grey-dark;
          z-index: -1;
        }
      }
    }
  }
}
