.filters {
  position: relative;
  z-index: 1;

  .filtersWrapper {
    overflow: visible;
    position: relative;

    @include displayFlex($flexDirection: column, $alignItems: flex-start);
    &.visible {
      img {
        transform: rotate(0deg);
        transition: transform 0.2s ease-out;
      }
      .filtersOpener {
        @include lg {
          margin-bottom: 1.5rem;
        }
      }
      .filtersControls {
        width: 100%;
        transform: translateY(0px);
        opacity: 1;
        transition: transform 0.25s ease-out, opacity 0.25s ease-out;
        @include lg {
          max-height: 5000px;
          transition: transform 0.25s ease-out, max-height 0.25s ease-out;
        }
      }
    }
    &.hidden {
      img {
        transform: rotate(180deg);
        transition: transform 0.2s ease-out;
      }
      .filtersControls {
        transform: translateY(-1500px);
        opacity: 0;
        transition: transform 0.25s ease-out, opacity 0.25s ease-out;

        * {
          pointer-events: none !important;
          visibility: hidden;
          transition: 0.3s visibility;
        }

        @include lg {
          max-height: 0;
          transition: transform 0.25s ease-out, max-height 0.25s ease-out;
        }
      }
    }
    &.overflow {
      overflow: hidden;
    }

    .filtersOpener {
      @include displayFlex($alignItems: center);
      width: 100%;
      background-color: $white;
      position: relative;
      z-index: 10;
      .title {
        margin-bottom: 0;

        @include lg {
          font-size: fontSize(2);
        }
        @include sm {
          font-size: fontSize(1.9);
        }
      }

      .filtersVisibilityControler {
        cursor: pointer;
        display: inline-flex;

        img {
          width: 21px;

          @include lg {
            width: 17px;
          }
          @include sm {
            width: 15px;
          }
        }
      }
    }
  }

  .filtersControls {
    @include displayFlex($flexDirection: column);
    gap: $gap-size-1x 0;
    position: relative;
    margin-top: $gap-size-normal;
    z-index: 0;

    @include lg {
      margin-top: 0;
    }

    .controlsRow {
      @include displayFlex($flexDirection: column);

      .mb-0 {
        margin-bottom: 0;
        + .checkboxesFilter {
          margin-top: $gap-size-normal;
        }
      }
    }

    .priceSlider {
      margin-top: -0.7rem;
      .MuiSlider-root {
        .MuiSlider-rail {
          background-color: $grey-dark;
          color: $grey-dark;
        }
        .MuiSlider-track {
          border-color: $grey-dark;
          background-color: $grey-dark;
        }
        .MuiSlider-thumb {
          width: 16px;
          height: 16px;
          background-color: $primary;

          &[data-index="0"] {
            margin-left: 8px;
          }
          &[data-index="1"] {
            margin-left: -8px;
          }
        }

        .MuiSlider-valueLabel {
          padding: 0.25rem 0.5rem;
        }
      }
    }

    .priceFilter {
      @include displayFlex;
      justify-content: space-between;

      input {
        width: 50px;
        padding: 0.2rem;
        text-align: center;
        &:first-child {
          margin-right: 0.9rem;
        }
      }
    }

    .checkboxesFilter {
      li {
        @include displayFlex;
        position: relative;
        margin-bottom: 1.1rem;
        &:last-child {
          margin-bottom: 0;
          span {
            cursor: pointer;
            position: relative;
            &::after {
              @include pseudoElementContent($bottom: 0, $left: 0, $height: 1px);
              background-color: $grey-dark;
              opacity: 0;
              transition: opacity 0.2s ease-out;
            }
            &:hover::after {
              opacity: 1;
              transition: opacity 0.2s ease-in;
            }
          }
        }

        label {
          display: inline-flex;
          align-items: center;
          input {
            margin-right: 0.75rem;
          }
        }
      }
    }
  }
}
