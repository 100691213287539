.stockWrapper {
  @include displayFlex($alignItems: stretch, $justifyContent: space-between);
  background-color: $white;
  position: relative;
  z-index: 2;
  margin-top: 1.9rem;
  margin-bottom: 2.5rem;

  @include lg {
    margin-bottom: 0;
  }

  .priceWrapper {
    @include displayFlex($alignItems: flex-start, $justifyContent: space-between, $flexDirection: column);
    color: $primary;

    .price {
      margin-bottom: 0 !important;
      line-height: 1 !important;

      strong {
        font-size: fontSize(3);
      }

      .regularPrice {
        color: $grey-mid;
      }
    }

    .stock {
      font-size: fontSize(1.7);
      letter-spacing: -0.43px;
      color: $green;
      font-family: $font-family-heading;
      margin-bottom: 0;

      &.noStock {
        color: $primary;
      }
    }
  }

  .controls {
    @include displayFlex($alignItems: flex-start, $justifyContent: space-between);
    min-height: 42px;
    height: 50px;
    position: relative;
    z-index: 10;
    max-width: 300px;
    width: $max-percentage;

    @include lg {
      max-width: 220px;
      height: 40px;
    }

    .amountControls {
      @include displayFlex($alignItems: center, $justifyContent: center);
      width: calc($half-percentage + 2rem);
      background-color: $grey-light;
      min-height: $max-percentage;
      color: $black;
      vertical-align: top;

      @include lg {
        width: calc(50% + 1rem);
      }

      .amountInner {
        @include displayFlex($alignItems: center, $justifyContent: center);
        width: $max-percentage;
        margin-left: -1rem;
      }

      .amount {
        font-size: fontSize(2);
        line-height: 1;
        padding: 0 $gap-size-1x;

        &.animated {
          animation: pulseInWithOpacity 0.25s ease-out;
        }

        @include lg {
          padding: 0 $gap-size-normal;
        }
      }

      button {
        background-color: transparent;
        color: $black;
        padding: 0;

        svg {
          width: 22px;

          @include lg {
            width: 18px;
          }
        }
        &:hover svg {
          fill: $primary;
        }
      }
    }

    .addToCart {
      @include displayFlex($alignItems: center, $justifyContent: center);
      width: calc($half-percentage - $gap-size-normal);
      background-color: $black;
      color: $white;
      font-size: fontSize(1.35);
      min-height: $max-percentage;
      letter-spacing: 0.18px;
      position: relative;
      z-index: 20;
      cursor: pointer;
      padding: 0 0.5rem;
      @include transitionOut(background-color);

      @include lg {
        font-size: fontSize(1.2);
        width: 50%;
        padding: 0;
      }

      &:hover {
        background-color: $primary;
        @include transitionIn(background-color);

        &::after {
          background-color: $primary;
          @include transitionIn(background-color);
        }
      }

      &::after {
        @include pseudoElementContent($top: 0, $left: 0, $width: 40px);
        transform: skew(-24deg, 0);
        transform-origin: top left;
        background-color: $black;
        z-index: -10;
        @include transitionOut(background-color);
      }

      strong {
        white-space: nowrap;
      }
    }
  }
}
