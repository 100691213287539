#drawing_canvas {
  position: absolute;
  width: 512px;
  height: 512px;
  top: -298px;
  bottom: 0;
  right: 0;
  pointer-events: none;
  z-index: 500;
}

#freeShipping {
  width: $max-percentage;
  margin-top: $gap-size-2x;

  &.not-free {
    .info strong:nth-child(2) {
      color: $grey;
      @include lg {
        text-align: right;
        color: $black;
      }
    }
  }

  &.free {
    .info strong:nth-child(1) {
      color: $grey;

      @include lg {
        display: none;
      }
    }
  }

  strong {
    transition: color 0.15s ease-in-out;
  }

  @include lg {
    padding: 0;
  }

  .info {
    @include displayFlex($alignItems: center, $justifyContent: space-between);
    padding: 0 $gap-size-normal;
    font-size: fontSize(1.75);

    @include lg {
      padding: 0;
    }
  }

  .slider {
    margin: 10px 0 0;
    position: relative;

    .inner {
      background-color: rgba($primary, 0.2);
    }

    &:after {
      @include pseudoElementContent($width: 0px, $height: 100%, $top: 0, $right: 0);
      background: $primary;
      z-index: 0;
    }

    span {
      z-index: 1;
      display: block;
      border: 2px;
      height: 26px;
      background-color: $primary;
      background-size: 30px 18px;
      position: relative;
      width: 0px;
      min-width: 50px;
      transition: width 1s ease-in-out;

      &:after {
        @include pseudoElementContent($width: 0, $height: 0, $top: 0, $right: -5px);
        border-style: solid;
        border-width: 13px 0 13px 5px;
        border-color: transparent transparent transparent $primary;
      }

      svg {
        height: 100%;
        padding: 3px;
        position: absolute;
        right: 5px;
        top: 0;
        fill: $white;
        &.backward {
          transform: scaleX(-1);
        }
      }
    }
  }

  + .sum-items {
    margin-top: $gap-size-1x;
  }
}
