.delivery-options,
.payment-options {
  padding-bottom: 0;
}

.delivery-option,
.payment-option {
  position: relative;
  padding: 0px 80px 40px 0px;
  &.disabled {
    pointer-events: none;
    user-select: none;
    opacity: 0.5;
  }

  &.free-now {
    .delivery-price,
    .payment-price {
      color: $primary;
    }
  }
}

.delivery-button,
.payment-button {
  @include displayFlex($alignItems: center);

  input + label {
    padding-left: $gap-half-size;
  }
}

.delivery-description,
.free-from,
.delivery-info,
.payment-description {
  font-weight: 300;
  color: $grey-mid-dark;
  display: block;
  margin-top: 13px;

  &.disabled {
    color: $grey-light;
  }
}

.free-from {
  color: $primary;
}

.delivery-price,
.payment-price {
  position: absolute;
  top: 0;
  right: 0;
  color: $half-black;
  font-weight: 500;

  &.disabled {
    color: $grey-light;
  }
}
