section:not(.productMain) figure {
  @include lg {
    aspect-ratio: 1/3;
    max-height: 290px;
  }
}

section {
  figure {
    height: 100%;

    img {
      object-fit: cover;
    }
  }

  p {
    margin-bottom: $gap-size-1x;
  }

  &.twoColumnsImageText {
    .leftCol {
      @include lg {
        order: 2;
      }
    }

    .rightCol {
      padding: 0.8rem 2rem;
      @include lg {
        order: 1;
      }
    }
  }

  &.twoColumnsFill {
    background-color: $section-background;
    color: $white;
    padding: 3.5rem 2rem 1rem 3.75rem;
    @include lg {
      padding: 0.8rem 2rem;
    }

    .leftCol {
      button {
        margin-top: -0.75rem;
      }
    }
  }

  &.twoColumnsImages {
    color: $white;
    min-height: 10rem;

    @include xlx {
      padding: 0 $gap-size-2x;
      flex-direction: row;
      justify-content: space-between;
    }
    @include lg {
      padding: 0 $gap-size-1x;

      + .twoColumnsImages {
        margin-top: $gap-size-normal;
      }
    }
    @include sm {
      padding: 0 $gap-size-normal;
      flex-direction: column;
      + .twoColumnsImages {
        margin-top: $gap-size-normal;
      }
    }

    .leftCol {
      background: center / cover no-repeat $image1;
      margin-right: 1rem;
      @include xlx {
        margin-right: 0;
        width: calc($half-percentage - $gap-half-size);
      }
      @include sm {
        width: $max-percentage;
      }
    }

    .rightCol {
      background: center / cover no-repeat $image2;
      margin-left: 1rem;
      @include xlx {
        width: calc($half-percentage - $gap-half-size);
        margin-left: 0;
        margin-top: 0;
      }
      @include sm {
        margin-top: $gap-size-normal;
        width: $max-percentage;
      }
    }

    .leftCol,
    .rightCol {
      padding: 2.3rem 1rem 1rem 3.8rem;
      @include lg {
        padding: 0.8rem 2rem;
      }
      @include md {
        height: 190px;
        padding: 0.8rem 2rem;
      }
      @include sm {
        height: 160px;
        padding: 0.8rem $gap-size-normal;
      }
    }
  }

  &.threeColumnsImages {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @include xlx {
      padding: 2rem $gap-size-2x 0;
    }
    @include lg {
      padding: 2rem $gap-size-1x 0;
    }
    @include sm {
      padding: $gap-size-1x $gap-size-normal 0;
    }

    .boxRow {
      @include displayFlex;

      @include lg {
        flex-direction: column;
      }

      .someCol {
        background: none;
        margin-right: 1rem;
        padding: 0 3rem 1rem 0;
        height: auto;

        @include lg {
          padding: 0 0 2rem;

          &:last-child {
            padding: 0;
          }
        }

        h2 {
          font-size: fontSize(3.4);
          @include lg {
            font-size: fontSize(3);
            margin-bottom: $gap-size-normal;
          }
        }

        p {
          margin-bottom: 1rem;
        }
        a {
          color: $primary;
        }
      }
    }
  }

  &.fourColumnsImages {
    @include displayFlex($flexDirection: column);

    @include xlx {
      padding: 2rem $gap-size-2x 0;
    }
    @include lg {
      padding: 2rem $gap-size-1x 0;
    }
    @include sm {
      padding: $gap-size-1x $gap-size-normal 0;
    }

    :not(h2) {
      color: $white;
    }

    .boxRow {
      @include displayFlex($justifyContent: space-between);

      @include xlx {
        flex-wrap: wrap;
        gap: $gap-size-normal;
      }
      @include lg {
        gap: unset;
      }

      @include sm {
        flex-direction: column;
      }

      .someCol {
        background: center / cover no-repeat $image1;
        margin-right: 1rem;
        padding: 2.3rem 1rem 1rem 3.8rem;
        &:nth-child(even) {
          background: center / cover no-repeat $image2;
        }
        @include xlx {
          width: calc(50% - $gap-half-size);
          margin-right: 0;
        }

        @include lg {
          height: 220px;
          padding: 0.8rem 2rem;
          margin-bottom: $gap-size-1x;
          &:last-child {
            margin-bottom: 0;
          }
        }

        @include md {
          height: 180px;
        }

        @include sm {
          padding: 0.8rem $gap-size-normal;
          width: 100%;
        }
      }
    }
  }

  &.twoColumnsBgImageText {
    color: $white;
    background: center / cover no-repeat $image3;

    .leftCol {
      padding: 3.5rem 2rem 1rem 3.75rem;
    }

    .rightCol {
      padding: 3.5rem 2rem 1rem 3.75rem;
      background-color: $half-black;
    }
  }
}
